import React, { useState } from "react"
import { Button, Modal } from "antd"
import PhoneField from "../../components/SettingsWidgets/PhoneField"
import { useTranslation } from "react-i18next"
import i18n from "../../utils/i18n"

const UpdatePhoneNumberModal = ({ defaultOpen = false, user }) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(() => defaultOpen)

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <Modal
      title={t("modal.updatePhoneNumber.title")}
      open={isModalOpen}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {i18n.t("phoneVerification.button.updateLater")}
        </Button>,
      ]}
      closable={false}
    >
      <PhoneField
        uid={user?.uid}
        phoneNumber={user?.phoneNumber}
        onUpdate={handleCancel}
      />
    </Modal>
  )
}

export default UpdatePhoneNumberModal
