import React from "react"
import classes from "./index.module.css"
import { Drawer, Spin } from "antd"
function Index({ isVisible }) {
  return (
    isVisible && (
      <div>
        <Drawer
          open={isVisible}
          contentWrapperStyle={{ display: "none" }}
        ></Drawer>
        <div className={classes.wrapper}>
          <Spin size="large"></Spin>
          {/* <div
            className="spinner"
            style={{ backgroundColor: "rgba(107, 98, 255, 0.8)" }}
          ></div> */}
        </div>
      </div>
    )
  )
}

export default Index
