import { getTodayDateMillis } from "../dateTime"
import { firestore } from "../firebase"

const rideCategory = {
  upcoming: {
    field: "locationInfo.dateMillis",
    condition: ">=",
    sortBy: "asc",
  },

  past: {
    field: "locationInfo.dateMillis",
    condition: "<",
    sortBy: "desc",
  },
}

/**
 * @param {string} uid - userId
 * @param {("client" | "driver" | "admin")} role - role of user
 * @param {("upcoming"|"past")} category - category of Ride -> upcoming or past rides
 * @param {("pending" | "approved" | "rejected" | "cancelled")} status - status of Ride ->
 */
export const getRides = (uid, role, category = "upcoming", status = ["pending", "approved"], driver = "all") => {
  const todaysMillis = getTodayDateMillis()
  let query = firestore.collection("rides")
  // CHANGE THIS LATER ON INCASE WE MERGE THE CLIENT/DRIVER QUERY
  if (role === "client") {
    query = query.where("createdBy.uid", "==", uid)
  }
  // GET RIDES
  // 1) If Upcoming - Get all rides their departureDate is upcoming or today. (departureDate >= today)
  // 2) If past - Get all rides their departureDate is  passed from today. (departureDate < today)
  if (status.length) {
    query = query.where("status", "in", status)
  }
  if (driver !=="all") {
    query = query.where("driverInfo.driverName", "==", driver)
  }
  query = query
    .where(
      rideCategory[category].field,
      rideCategory[category].condition,
      todaysMillis
    )
    .orderBy(rideCategory[category].field, rideCategory[category].sortBy)
  return query
}

export const getRidesByDate = (startTimeOfDay, EndTimeOfDay) => {
  let query = firestore.collection("rides")
  query = query.where("driveDates", "array-contains", startTimeOfDay)
  return query
}

export const getCalendarRides = (driver = "all", status = ["pending", "approved"]) => {
  let query = firestore.collection("rides")
  if(driver !=="all") {
    query = query.where("driverInfo.driverName", "==", driver)
  }
  if (status.length) {
    query = query.where("status", "in", status)
  }
  return query
}

export const getDrivers = () => {
  let query = firestore.collection("users")
  query = query.where("role", "==", "driver")
  return query
}

//get driver phone number
export const getDriverPhoneNumber = async (driverId) => {
  const res = await firestore.collection("users").doc(driverId).get()
  if (res.exists) {
    return res.data()?.phoneNumber || ""
  } else {
    return ""
  }
}
