import React, { useState } from "react"
import classes from "./index.module.css"
import { Button, AutoComplete } from "antd"
import { PropTypes } from "prop-types"
import isEqual from "lodash/isEqual"
import { useTranslation } from "react-i18next"
import RideCancelConfirmation from "../../../components/RideItemActions/CancelConfirmation"

export default function Index({
  variant,
  isAlreadySelfAssigned,
  areChangesMadeToTheMission,
  driverInfo,
  isAlreadyCancelled,
  driversList = [],

  onCancelled,
  onBookedOrSaved,
  onAssignSelf,
  onDriverInfoChange,
  onApprove
  // onChangeAssignee,
}) {
  const [autoCompleteValue, setAutoCompleteValue] = useState("")
  const { t } = useTranslation()
  const onDriverSelected = (val, data) => {
    const newDriverInfo = {
      driverId: data.key,
      driverName: data.value,
    }
    setAutoCompleteValue(val)
    if (isEqual(driverInfo, newDriverInfo)) return
    onDriverInfoChange(newDriverInfo)
  }

  return (
    <div className={classes.footer}>
      <div>
        <strong>{t("modal.rideInfoModal.footer.assignedDriver")}:</strong>
        <p>
          {driverInfo?.driverName ||
            t("modal.rideInfoModal.footer.notAssigned")}
        </p>
      </div>
      <div className={classes.actionButtons}>
        {variant === "admin" && (
          <AutoComplete
            style={{ width: 200, margin: "auto"}}
            placeholder={t("modal.rideInfoModal.footer.findDriver")}
            onSelect={onDriverSelected}
            value={autoCompleteValue}
            onChange={setAutoCompleteValue}
            // defaultValue={driverInfo?.driverName}
            // allowClear
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          >
            {driversList.map((val) => {
              return <AutoComplete.Option key={val.uid} value={val.username} />
            })}
          </AutoComplete>
        )}
      <div className={classes.assign}>
       {variant === "driver" && (
          <Button
            onClick={() =>
              onAssignSelf(isAlreadySelfAssigned ? "UN_ASSIGN" : "ASSIGN")
            }
            type="primary"   
          >
            {isAlreadySelfAssigned
              ? t("modal.rideInfoModal.footer.button.unAssignDriver")
              : t("modal.rideInfoModal.footer.button.assignToYourself")}
          </Button>
        )}   
      </div>
      <div className={classes.approve }>
        {variant === "driver" && (
          <Button 
            className="ant-btn-primary"
            onClick={onApprove}
          >
            {t("default.approve")}
          </Button>
        )}
      <div className={classes.book}>
         {(variant === "admin" || variant === "driver") && (
          <Button
            onClick={() => {
              setAutoCompleteValue("")
              onBookedOrSaved()
            }}
            disabled={!areChangesMadeToTheMission}
            type="primary"
          >
  
          </Button>
        )}
      </div>
      </div>
      <div className={classes.save}>
          <Button
            onClick={() => {
              setAutoCompleteValue("")
              onBookedOrSaved()
            }}
            disabled={!areChangesMadeToTheMission}
            type="primary"
          >
            {variant === "client"
              ? t("modal.rideInfoModal.footer.button.book")
              : t("modal.rideInfoModal.footer.button.save")}
          </Button>
      </div>
      <div className={classes.cancel}>
        {variant !== "client" && (
          <RideCancelConfirmation
            onConfirm={onCancelled}
            disabled={isAlreadyCancelled}
          />
        )}
      </div>
      </div>
    </div>
  )
}
Index.propTypes = {
  /**
   * Variants shows different types of entities on footer based user role
   */
  variant: PropTypes.oneOf(["admin", "client", "driver", "client-restricted"]),
  onCancelled: PropTypes.func,
  onBookedOrSaved: PropTypes.func,
  onAssignSelf: PropTypes.func,
  /**
   * True if ride is already cancelled
   */
  isAlreadyCancelled: PropTypes.bool,
  /**
   * True if ride is assigned to current user
   */
  isAlreadySelfAssigned: PropTypes.bool,
  /**
   * True if changes are made to the ride information
   */
  areChangesMadeToTheMission: PropTypes.bool,
  driverInfo: PropTypes.shape({
    driverId: PropTypes.string,
    driverName: PropTypes.string,
  }),
  /**
   * Fired a function with driver info in first parameter if driver info is changed
   */
  onDriverInfoChange: PropTypes.func,
}
