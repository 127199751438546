import React from "react"
import classes from "./index.module.css"
import { Typography } from "antd"
import { RightOutlined } from "@ant-design/icons"
import moment from "moment"
import { PropTypes } from "prop-types"
import i18n from "../../../utils/i18n"

function FullTitle({
  fromAutoDescription,
  toAutoDescription,
  startDate,
  endDate,
  totalDistance,
  routeLink,
}) {
  const parseTitleFromDescription = (description = "") => {
    const titleArray = description?.split(",")
    var titleArrayLength = titleArray.length - 2
    if (titleArray.length < 2) {
      titleArrayLength = 0
    }
    const titleString = titleArray[titleArrayLength]
    return titleString
  }
  const toTitleString = parseTitleFromDescription(toAutoDescription)
  const fromTitleString = parseTitleFromDescription(fromAutoDescription)
  const dayTitle = moment(startDate).format("DD MMM - HH[:]mm")
  return (
    <div className={classes.titleContainer}>
      <Typography.Title level={4} style={{ margin: 0, lineHeight: 2 }}>
        {fromTitleString} <RightOutlined /> {toTitleString}
      </Typography.Title>

      <Typography.Title level={5} className={classes.title}>
        {dayTitle} <RightOutlined style={{ marginLeft: 10, marginRight: 10 }} />{" "}
        {moment(endDate).format("DD MMM - HH[:]mm")}{" "}
        <div className={classes.ballSymbol}></div> {totalDistance}
      </Typography.Title>

      <div className={classes.routelink}>
        <a href={routeLink} target="_blank">
          {i18n.t("default.directionLink")}
        </a>
      </div>
    </div>
  )
}
FullTitle.propTypes = {
  /**
   * Description/name of the pickup location
   */
  fromAutoDescription: PropTypes.string,
  /**
   * Description/name of the destination location
   */
  toAutoDescription: PropTypes.string,
  /**
   * Start date of trip
   */
  startDate: PropTypes.any,
  /**
   * End date of trip
   */
  endDate: PropTypes.any,
  /**
   * Total disctance of trip
   */
  totalDistance: PropTypes.string,
}

export default FullTitle
