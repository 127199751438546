import { Button, Space } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"

const PaginationBar = ({ isLoading, pageNo, isStart, isEnd, pageHandler }) => {
  return (
    <Space>
      <Button
        onClick={() => pageHandler("previous")}
        icon={<LeftOutlined />}
        disabled={pageNo === 1 || isStart || isLoading}
      />

      <Button
        loading={isLoading}
        disabled={true}
        className="pagination-number-chip"
      >
        {pageNo}
      </Button>

      <Button
        onClick={() => pageHandler("next")}
        icon={<RightOutlined />}
        disabled={isEnd || isLoading}
      />
    </Space>
  )
}

export default PaginationBar
