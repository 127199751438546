import React from "react"
import { tileData } from "./data"
import Tile from "../../components/Tile"
import { PropTypes } from "prop-types"
import classes from "./index.module.css"
function TileList({ data = tileData, onClick }) {
  return (
    <>
      <div className={classes.tileWrapper}>
        <div />
        {data.map((val, i) => {
          let { name, title, component } = val
          return (
            <Tile
              key={i}
              name={name}
              title={title}
              component={component}
              onClick={() => {
                console.log("clicked")
                onClick(name)
              }}
            ></Tile>
          )
        })}
        <div />
      </div>
    </>
  )
}

TileList.propTypes = {
  /**
   * Delegates onClick to TitleList i.e parent
   */
  onClick: PropTypes.func,
  /**
   * Data in array for the tile
   */
  data: PropTypes.any,
}
export default TileList
