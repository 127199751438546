import React, { useRef, useState, useEffect } from "react"
import { Modal, Button, notification } from "antd"
import classes from "./index.module.css"
import { useDispatch } from "react-redux"
import { loginWithMicrosoft } from "../../redux/slices/authSlice"
import { setLoading } from "../../redux/slices/appSlice"

import ToolDivisionLogo from "../../images/tooldivision-logo.png"
import PlasticDivisionLogo from "../../images/plastic-logo.png"
import { ReactComponent as MicrosoftIcon } from "../../images/microsoft-icon.svg"
import { tenantVariants } from "../../utils/constants"

function AuthMicrosoft() {
  const dispatch = useDispatch()
  const ref = useRef()
  const [variant, setVariant] = useState(tenantVariants.plasticTenant)

  const handleMicrosoftLogin = () => {
    dispatch(setLoading(true))
    dispatch(loginWithMicrosoft(variant))
      .unwrap()
      .then(() => {})
      .catch((err) => {
        console.log(err?.message, "Ylolo")
        notification.error({ message: err?.message })
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const animateUnderLine = (variant) => {
    const element = ref?.current
    let distance = variant === tenantVariants.plasticTenant ? 0 : 100
    if (element) {
      element.animate([{ transform: `translateX(${distance}%)` }], {
        duration: 200,
        fill: "forwards",
      })
    }
  }
  useEffect(() => {
    animateUnderLine(variant)
  }, [variant])

  return (
    <div>
      <Modal
        open={true}
        closable={false}
        title={
          <div className={classes.buttonWrapper}>
            <span ref={ref} className={classes.after}></span>
            <div
              className={classes.slidingButton}
              onClick={() => setVariant(tenantVariants.plasticTenant)}
            >
              Plastic
            </div>
            <div
              className={classes.slidingButton}
              onClick={() => setVariant(tenantVariants.tooldivisionTenant)}
            >
              Tool Division
            </div>
          </div>
        }
        footer={
          <div className={classes.footerWrapper}>
            <div className={classes.imgWrapper}>
              <img
                src={
                  variant === tenantVariants.plasticTenant
                    ? PlasticDivisionLogo
                    : ToolDivisionLogo
                }
                alt="Simoldes group"
              />
            </div>
          </div>
        }
      >
        <div>
          <Button
            type="primary"
            onClick={handleMicrosoftLogin}
            className={classes.microsoftBtn}
            icon={<MicrosoftIcon />}
          >
            Login with Microsoft
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AuthMicrosoft
