import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"
import firebaseConfig from './firebase-config.json';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig.sdk)
}

export const auth = firebase.auth()
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
// AZURE FIRST TENANT PROVIDER
export const tooldivisionTenantProvider = new firebase.auth.OAuthProvider(
  "microsoft.com"
).setCustomParameters({
  tenant: firebaseConfig.tenants.REACT_APP_AZURE_AD_TENANT_ID, //process.env.REACT_APP_AZURE_AD_TENANT_ID
})
tooldivisionTenantProvider.addScope("user.read")
// AZURE SECOND TENANT PROVIDER
export const plasticTenantProvider = new firebase.auth.OAuthProvider(
  "microsoft.com"
).setCustomParameters({
  prompt: "select_account",
  tenant: firebaseConfig.tenants.REACT_APP_AZURE_AD_TENANT_ID_SECOND, //process.env.REACT_APP_AZURE_AD_TENANT_ID
})
plasticTenantProvider.addScope("user.read")

export const locatlPersistence = () =>
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

// Firestore exports
export const firestore = firebase.firestore()
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp
export const fbTimestamp = firebase.firestore.Timestamp
export const fromMillis = firebase.firestore.Timestamp.fromMillis
export const increment = firebase.firestore.FieldValue.increment

export const fbFunction = firebase.functions

// SETUP HOST FOR FIREBASE FUNCTIONS IN DEV
if (process?.env?.NODE_ENV === "development") {
  console.log("ENABLE FIREBASE FUNCTION EMULATOR")
  fbFunction().useEmulator("localhost", 5001)
}

// Storage exports
export const storage = firebase.storage()

export default firebase
