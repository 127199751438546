import { useSelector } from "react-redux"

import Spinner from "./components/Spinner"
import RoutesEnhancer from "./Routes"

import "antd/dist/antd.css"

function App() {
  const { loading } = useSelector((state) => state.app)

  return (
    <div className="app">
      <Spinner isVisible={loading} />
      <RoutesEnhancer />
    </div>
  )
}

export default App
