import { Select, Space } from "antd"
import { useAppSelector } from "../../redux/slices/appSlice"
import classes from "./index.module.css"
import i18n from "../../utils/i18n"

const { Option } = Select

const RideFiltersClient = ({ className, style, changeHandler }) => {
  const { drivers } = useAppSelector()

  return (
    <div className={classes["div"]}>
      <Space className={className} style={style}>
        <Select
          defaultValue="all"
          style={{ width: 200 }}
          onChange={(value) => changeHandler("driver", value)}
        >
          <Option value="all">{i18n.t('default.all')}</Option>

          {drivers.map((driver) => {
            return <Option value={driver.username}>{driver.username}</Option>
          })}
        </Select>

        <Select
          mode="multiple"
          defaultValue={["approved", "pending"]}
          style={{ minWidth: "100%" }}
          onChange={(value) => changeHandler("status", value)}
          allowClear
        >
          <Option value="pending">{i18n.t("default.pending")}</Option>
          <Option value="approved">{i18n.t("default.approved")}</Option>
          <Option value="rejected">{i18n.t("default.rejected")}</Option>
          <Option value="cancelled">{i18n.t("default.cancelled")}</Option>
        </Select>
      </Space>
    </div>
  )
}

export default RideFiltersClient
