export const rideRecord = {
  flightNumber: "CAW-97",
  createdBy: {
    uid: "124",
    username: "farhan",
  },
  rideId: "1xco3fPSbyFWLNz9ffZJ",
  mainPhone: "+923001234567",
  numberPeople: 98,
  driverInfo: {
    driverName: "farhan",
    driverId: "130",
  },
  createdAt: {
    seconds: 1640775208,
    nanoseconds: 795000000,
  },
  distance: {
    value: 28969,
    text: "28.9 km",
  },
  status: "processing",
  mainPerson: "Shamaz",
  updatedAt: {
    seconds: 1640775208,
    nanoseconds: 795000000,
  },
  eventTitle: "Event Title 97",
  locationInfo: {
    date: "asdasd",
    toAuto: {
      lat: 148.5072178,
      description: "London, UK 97",
      lng: 96.8724138,
    },
    fromAuto: {
      description: "Africa 97",
      lat: 148.5072178,
      lng: 96.8724138,
    },
    dateMillis: 1642878000000,
  },
  duration: {
    value: 2607,
    text: "43 mins",
  },
  id: "1xco3fPSbyFWLNz9ffZJ",
}

export const allowedAuthorizationRoles = ["admin", "client", "driver"]

export const adminGroups = ["d250cb25-50c0-4828-b764-c6254001dba9", "11d8e320-8846-42c2-8e26-9a298b353015", "5ec53a3f-9f23-4b20-a1eb-06aa9cf0b616"]

export const driverGroups = ["e855fef6-99b6-4aa8-ac92-583c156b293e", "6adf2499-b67a-40a5-a584-ddfd6d5cc6d3", "1160455f-d3dd-4795-a941-8a813c6a2698"]

export const clientGroups = ["0abe62c8-e9e1-4998-b121-822e8322605a", "a2180a6c-c4e8-4708-a078-2caf1b52f303", "cba182b5-abca-42ca-bc2d-15fdeb3063c8"]

export const authorizationRoles = {
  admin: "Driverlogics.Admin",
  client: "Driverlogics.Client",
  driver: "Driverlogics.Driver",
}

export const tenantVariants = {
  tooldivisionTenant: "tooldivision",
  plasticTenant: "plastic",
}
