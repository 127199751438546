import React, { useEffect, useState } from "react"

import { Layout, Typography } from "antd"
import { Outlet } from "react-router-dom"
import { useDispatch } from "react-redux"
import { MainLayout } from "../../layouts"

import classes from "./index.module.css"
import { useAuthSelector } from "../../redux/slices/authSlice"
import { useTranslation } from "react-i18next"
import PhoneField from "../../components/SettingsWidgets/PhoneField"

const { Content } = Layout
function SettingPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user } = useAuthSelector()
  const uid = user?.uid
  // const username = user?.username
  const userRole = user?.role || "client-restricted"

  return (
    <MainLayout>
      <Content className={classes["contentWrap"]}>
        <Typography.Title level={4}>{t("setting.title")}</Typography.Title>
      </Content>
      <Content className={classes["contentWrap"]}>
        <PhoneField uid={uid} phoneNumber={user?.phoneNumber || ""} />
      </Content>
      <Outlet />
    </MainLayout>
  )
}

export default SettingPage
