import React from "react"
import { Button, Alert, Form, Input, InputNumber, message } from "antd"

import { fbFunction } from "../../utils/firebase"
import { useDispatch } from "react-redux"
import { getUserData } from "../../redux/slices/authSlice"
import { useMemo } from "react"
import i18n from "../../utils/i18n"

const PhoneField = ({ uid, phoneNumber, onUpdate }) => {
  const dispatch = useDispatch()
  const [loadingStatus, setLoadingStatus] = React.useState(false)
  const [validationStatus, setValidationStatus] = React.useState({
    type: "",
    msg: "",
  })
  const [isCodeSent, setIsCodeSent] = React.useState(false)
  const [isCodeExpired, setIsCodeExpired] = React.useState(false)
  const [form] = Form.useForm()
  const phoneValue = Form.useWatch("phoneNumber", form)

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo)
  }
  const sendCodeHandler = async (values) => {
    try {
      setIsCodeExpired(false)
      setValidationStatus({
        type: "",
        msg: "",
      })
      setLoadingStatus("loading")
      const sendPhoneCode = fbFunction().httpsCallable("sendPhoneCode")
      const res = await sendPhoneCode({
        phoneNumber: `351${values?.phoneNumber}`,
      })
      if (!!res.data.id) {
        setIsCodeSent(true)
      } else {
        throw new Error("Something went wrong")
      }
    } catch (err) {
      console.log("err->", err)
      setValidationStatus({
        type: "error",
        msg: err.message,
      })
    } finally {
      setLoadingStatus(false)
    }
  }
  const verifyCodeHandler = async (values) => {
    try {
      setValidationStatus({
        type: "",
        msg: "",
      })
      setLoadingStatus("loading")
      const verifyPhoneCode = fbFunction().httpsCallable("verifyPhoneCode")
      const res = await verifyPhoneCode({
        phoneNumber: `351${values?.phoneNumber}`,
        code: `${values.mfaCode}`,
      })
      if (res === "verified") {
        message.success(i18n.t("phoneVerification.message.success"))
        dispatch(getUserData({uid}))
        setIsCodeSent(false)
        onUpdate?.()
      }
    } catch (err) {
      console.log("err->", err)
      setValidationStatus({
        type: "error",
        msg: err.message,
      })
      if (err.message?.includes("expired")) {
        setIsCodeExpired(true)
      }
    } finally {
      setLoadingStatus(false)
    }
  }
  const onFinish = (values) => {
    console.log("onFinish:", values)
    if (isCodeSent) {
      verifyCodeHandler(values)
    } else {
      sendCodeHandler(values)
    }
  }

  const defaultPhoneNumber = useMemo(() => {
    if (phoneNumber?.startsWith("92")) {
      return phoneNumber.slice(2)
    } else if (phoneNumber?.startsWith("351")) {
      return phoneNumber.slice(3)
    } else {
      return phoneNumber || ""
    }
  }, [])
  return (
    <Form
      form={form}
      name="basic"
      layout="vertical"
      initialValues={{
        phoneNumber: defaultPhoneNumber,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label={i18n.t("default.phoneNumber")}
        name="phoneNumber"
        rules={[
          {
            required: true,
            message: i18n.t("phoneVerification.error.phoneNumber"),
          },
        ]}
      >
        <InputNumber
          type="tel"
          prefix="+351"
          style={{ width: "100%" }}
          disabled={loadingStatus === "loading" || isCodeSent}
        />
      </Form.Item>
      {isCodeSent && (
        <Form.Item
          label={i18n.t("phoneVerification.message.verifyCode")}
          name="mfaCode"
          rules={[
            {
              required: true,
              message: i18n.t("phoneVerification.message.verifyCode"),
            },
            {
              validator: (_, value) =>
                `${value}`?.length === 6
                  ? Promise.resolve()
                  : Promise.reject(new Error(i18n.t("phoneVerification.error.tooShort"))),
            },
          ]}
        >
          <InputNumber
            minLength={6}
            maxLength={6}
            type="number"
            style={{ width: "100%" }}
            disabled={loadingStatus === "loading"}
          />
        </Form.Item>
      )}

      {validationStatus?.type !== "" && (
        <Form.Item>
          <Alert
            message={validationStatus?.msg}
            type={validationStatus?.type}
            showIcon
          />
        </Form.Item>
      )}
      <Form.Item>
        {isCodeSent ? (
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingStatus === "loading"}
          >
            {i18n.t("phoneVerification.button.verifyCode")}
          </Button>
        ) : (
          <Button
            type="primary"
            htmlType="submit"
            disabled={phoneValue === phoneNumber}
            loading={loadingStatus === "loading"}
          >
            {i18n.t("phoneVerification.button.updateCode")}
          </Button>
        )}
        {isCodeSent && isCodeExpired && (
          <Button
            type="link"
            htmlType="button"
            disabled={phoneNumber}
            loading={loadingStatus === "loading"}
            onClick={() => sendCodeHandler({ phoneNumber })}
          >
            {i18n.t("phoneVerification.button.resendCode")}
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

export default PhoneField
