import React, { useCallback, useEffect } from "react"

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete"

import { PropTypes } from "prop-types"
import { Select } from "antd"
const { Option } = Select
function AddressInput(props) {
  const { placeholder, selected, seedValue, style, onChange } = props
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete(
    {
      requestOptions: {
       /* Define search scope here */
       componentRestrictions: {
         country: ["PT"]
       }
     },
   }
  )

  const handleInput = useCallback(
    (e) => {
      // Update the keyword of the input element

      setValue(e.target.value)
    },
    [setValue]
  )
  useEffect(() => {
    // console.log(seedValue, value, "ERROR IN 2")
    if (seedValue) {
      handleSelect(seedValue)
    } else {
      setValue(undefined, false)
    }
  }, [seedValue])
  const handleSelect = (description) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"

    setValue(description, false)
    clearSuggestions()

    // Get latitude and longitude via utility functions
    getGeocode({ address: description, region: "PT" })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        selected({ description, lat, lng })
      })
      .catch((error) => {
        console.log("😱 Error: ", error)
      })
  }

  const options = data.map(
    ({ place_id, description, structured_formatting }) => (
      <Option key={place_id} value={description}>
        <strong>{structured_formatting.main_text}</strong>
        <br />
        <small>{structured_formatting.secondary_text}</small>
      </Option>
    )
  )

  return (
    <Select
      showSearch
      value={value ? value : seedValue ? seedValue : undefined}
      onInputKeyDown={handleInput}
      onSelect={handleSelect}
      onChange={onChange}
      disabled={!ready}
      showArrow={false}
      notFoundContent={null}
      placeholder={placeholder}
      className="driverLogicInput"
      style={style}
    >
      {status === "OK" && options}
    </Select>
  )
}
AddressInput.propType = {
  placeholder: PropTypes.string,
  /**
   * Fires after location selection with object of lng,lat,description
   */
  selected: PropTypes.func,
  /**
   * In case the value in the address needs to be prefilled. Pass location description in this prop
   */
  seedValue: PropTypes.string,
}
export default AddressInput
