import React from "react"

import { Table, Tag } from "antd"
import { PropTypes } from "prop-types"
import { displayDate } from "../../utils/dateTime"

// import classes from "./index.module.css"

const statusBadeColors = {
  approved: "green",
  pending: "geekblue",
  "no driver": "geekblue",
  rejected: "volcano",
  cancelled: "volcano",
}
const columns = [
  {
    title: "Mission ID",
    dataIndex: "rideId",
    key: "rideId",
    ellipsis: true,
    width: 130,
    render: (text) => <span>{text}</span>,
  },
  {
    title: "Date",
    dataIndex: ["locationInfo", "date"],
    key: "locationInfo.date",
    render: (text) => <span>{displayDate(text, "dd/MM/yyyy [HH:mm]")}</span>
  },
  {
    title: "from",
    dataIndex: ["locationInfo", "fromAuto", "description"],
    key: "locationInfo.fromAuto",
  },
  {
    title: "To",
    dataIndex: ["locationInfo", "toAuto", "description"],
    key: "locationInfo.toAuto",
  },
  {
    title: "Driver",
    dataIndex: ["driverInfo", "driverName"],
    key: "driverInfo.driverName",
  },
  {
    title: "# Passengers",
    dataIndex: "numberPeople",
    key: "numberPeople",
  },
  {
    title: "Requested by",
    dataIndex: ["createdBy", "email"],
    key: "createdBy",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status, record) => {
      const tags = [status]
      if (!record.driverInfo) {
        tags.push("no driver")
      }
      return (
        <>
          {tags.map((tag, i) => {
            return (
              <Tag color={statusBadeColors[tag]} key={tag + i}>
                {tag.toUpperCase()}
              </Tag>
            )
          })}
        </>
      )
    },
  },
]

function TableForRides({
  isLoading,
  dataSource = [],
  ActionComponent,
  onActionClick,
}) {
  return (
    <Table
      pagination={{ position: ["none", "none"] }}
      columns={[
        ...columns,
        {
          title: "Action",
          key: "action",
          dataIndex: "status",
          width: 374,
          render: (status, record) => {
            if (!ActionComponent) {
              return null
            }
            return (
              <ActionComponent
                status={status}
                record={record}
                onActionClick={onActionClick}
              />
            )
          },
        },
      ]}
      dataSource={dataSource}
      loading={isLoading}
    />
  )
}
TableForRides.propTypes = {
  /**
   * List of Rides
   */
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Id of the ride
       */
      key: PropTypes.string,
      /**
       * Id of the ride
       */
      rideId: PropTypes.string,
      /**
       * Status of the ride
       */
      status: PropTypes.string,
      /**
       * Creator of the ride
       */
      createdBy: PropTypes.shape({
        /**
         * Id of the user who created the ride
         */
        uid: PropTypes.string,
        /**
         * Name of the user who created the ride
         */
        username: PropTypes.string,
      }),
      /**
       * A person who is assigneed the ride
       */
      driverInfo: PropTypes.shape({
        /**
         * Id of the driver
         */
        driverId: PropTypes.string,
        /**
         * Name of the driver
         */
        driverName: PropTypes.string,
      }),
      /**
       * Date object representing creation date of ride
       */
      // createdAt: PropTypes.oneOfType([
      //   PropTypes.string,
      //   PropTypes.instanceOf(Date),
      // ]),
      createdAt: PropTypes.string,
      /**
       * Date object representing last time ride updated
       */
      updatedAt: PropTypes.string,

      /**
       * Title for the ride.
       */
      eventTitle: PropTypes.string,
      /**
       * Details for the ride parameters
       */
      locationInfo: PropTypes.shape({
        /**
         * Date object representing last time ride updated
         */
        date: PropTypes.string,
        dateMillis: PropTypes.number,
        /**
         * Pickup location of the ride.
         */
        fromAuto: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
          description: PropTypes.string,
        }),
        /**
         * Drop location of the ride.
         */
        toAuto: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
          description: PropTypes.string,
        }),
      }),
      /**
       * Distance b/w pickup-drop location of the ride.
       */
      distance: PropTypes.shape({
        /**
         * text representing the distance in Km.
         */
        text: PropTypes.string,
        /**
         * Value representing the distance in meter.
         */
        value: PropTypes.number,
      }),
      /**
       * Duration b/w pickup-drop location of the ride.
       */
      duration: PropTypes.shape({
        /**
         * text representing the duration in HMS.
         */
        text: PropTypes.string,
        /**
         * Value representing the duration in seconds.
         */
        value: PropTypes.number,
      }),
      /**
       * Name of the contact person.
       */
      mainPerson: PropTypes.string,
      /**
       * Phone number of the contact person.
       */
      mainPhone: PropTypes.string,
      /**
       * Number of people to transport.
       */
      numberPeople: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

      /**
       * Obs
       */
      Obs: PropTypes.string
    })
  ),
  /**
   * Fired when any of ride actions clicked
   */
  ActionComponent: PropTypes.any,
  /**
   * isLoading representing boolean when data is fetching
   */
  isLoading: PropTypes.bool,
  /**
   * Fired when any of ride actions clicked
   */
  onActionClick: PropTypes.func,
}

export default TableForRides
