import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  auth,
  firestore,
  locatlPersistence,
  // MICROSOFT AZURE TENANT PROVIDERS
  tooldivisionTenantProvider,
  plasticTenantProvider,
} from "../../utils/firebase"
import {
  adminGroups,
  clientGroups,
  driverGroups,
  tenantVariants,
} from "../../utils/constants"
import { useSelector } from "react-redux"
import jwt_decode from "jwt-decode"
import { getUserGroups } from "../../utils/ad"
import i18n from "../../utils/i18n"

// const CLOUD_DEFAULT_PROFILE_URL = "https://placeholder.api"

const initialState = {
  isLoggedIn: false,
  user: null,
  redirectUrl: null,
  loading: true,
  isNewUser: false,
  userProfileStatus: null, // null | IN_PROGRESS | COMPLETED
  email: null
}

export const updateUsername = createAsyncThunk(
  "auth/updateUsername",
  async ({ username, uid }, thunkApi) => {
    try {
      await firestore.doc(`users/${uid}`).update({ username })
      const userData = await thunkApi.dispatch(getUserData({uid}))
      return userData
    } catch (err) {
      return thunkApi.rejectWithValue(err.message || "Couldn't Login")
    }
  }
)
export const getUserData = createAsyncThunk(
  "auth/getUserData",
  async ({uid, groups = undefined}, thunkApi) => {
    try {

      const result = (await firestore.doc(`users/${uid}`).get()).data()
      
      return result
    } catch (err) {
      return thunkApi.rejectWithValue(err.message || "Couldn't get User Data ")
    }
  }
)

export const onUserSnapshot = createAsyncThunk(
  "auth/onUserSnapshot",
  (uid, { dispatch }) => {
    let userSnapshot

    userSnapshot = firestore
      .collection("users")
      .doc(uid)
      .onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            const data = snapshot.data()
            dispatch(updateUserData(data))
          }
        },
        (error) => {
          console.log(`error`, error)
        }
      )
    return userSnapshot
  }
)
export const signup = createAsyncThunk(
  "auth/signup",
  async ({ email, password, username, role = "client" }, thunkApi) => {
    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      )

      await firestore.doc(`users/${user?.uid}`).set({
        uid: user?.uid,
        email: user?.email,
        username: user?.email,
        role: role || "student",
        // photoURL: CLOUD_DEFAULT_PROFILE_URL,
      })

      const userData = await thunkApi.dispatch(getUserData({uid: user?.uid}))
      thunkApi.dispatch(updateAuthLoading(false))
      return userData
    } catch (err) {
      console.log(`err`, err)
      return thunkApi.rejectWithValue(err.message || "Couldn't signup user")
    }
  }
)
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, thunkApi) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password)
      const userData = await thunkApi.dispatch(getUserData({uid: user?.uid}))
      thunkApi.dispatch(updateAuthLoading(false))
      return userData
    } catch (err) {
      console.log(`err`, err)
      return thunkApi.rejectWithValue(err || "Couldn't Login")
    }
  }
)
export const loginWithMicrosoft = createAsyncThunk(
  "auth/loginWithMicrosoft",
  async (tenantVariant, thunkApi) => {
    try {
      const provider =
        tenantVariant === tenantVariants.tooldivisionTenant
          ? tooldivisionTenantProvider
          : plasticTenantProvider
      // await locatlPersistence()
      const { user, additionalUserInfo, credential } = await auth.signInWithPopup(provider)

      // OAuth access and id tokens can also be retrieved:
      const idToken = credential.idToken
      const decodedIdToken = jwt_decode(idToken)

      let newGroups = decodedIdToken?.groups || undefined

      try{
        await auth.currentUser.getIdToken(true)
        const accessToken = credential.accessToken
        localStorage.setItem("AZ_TOKEN", credential.accessToken)

        if(accessToken) {
          const AD = await getUserGroups(accessToken)
          if(!AD?.error){
            if(AD && AD.value && AD.value.length){
              newGroups = AD.value.map(g => g?.id)
            }else {
              return null
            }
          }
        }
      }catch(err){
        console.log("Failed to get user groups", err)
      }

      let role = "Unknown"
      if (newGroups.some(group => adminGroups.includes(group))) {
        role = "admin"
      } else if (newGroups.some(group => clientGroups.includes(group))) {
        role = "client"
      } else if (newGroups.some(group => driverGroups.includes(group))) {
        role = "driver"
      }


      const udata = {
        uid: user?.uid,
        email: user?.email,
        username: user?.displayName || "",
        role: role,
        groups: newGroups,
        photoURL: user?.photoURL || "",
        azUserUid: decodedIdToken?.oid || "",
        phoneNumber: "",
      }

      if (additionalUserInfo?.isNewUser) {
        await firestore.doc(`users/${user?.uid}`).set(udata)
      }else {
        await firestore.doc(`users/${user?.uid}`).update({ groups: newGroups, role })
      }
      const userData = await thunkApi.dispatch(getUserData({uid: user?.uid, groups: newGroups}))
      thunkApi.dispatch(updateAuthLoading(false))
      return userData
    } catch (err) {
      console.log(`err`, err)
      return thunkApi.rejectWithValue(err || i18n.t("default.error.login.failed"))
    }
  }
)
export const logoutAction = createAsyncThunk(
  "auth/logout",
  async (_, thunkApi) => {
    try {
      await auth.signOut()
      localStorage.removeItem("AZ_TOKEN")
      console.log("logout successfully")
      return null
    } catch (err) {
      return thunkApi.rejectWithValue(err.message || i18n.t("default.error.login.failed"))
    }
  }
)
export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (password, thunkApi) => {
    try {
      const user = auth.currentUser
      return await user?.updatePassword(password)
    } catch (err) {
      return thunkApi.rejectWithValue(err || i18n.t("default.error.password.change"))
    }
  }
)

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (email, thunkApi) => {
    try {
      return await auth.sendPasswordResetEmail(email)
    } catch (err) {
      return thunkApi.rejectWithValue(
        err.message || "Couldn't Send Password Reset Email"
      )
    }
  }
)

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload
    },
    updateUserData: (state, action) => {
      state.user = action.payload
      state.userProfileStatus = action.payload?.userProfileStatus
      if (!!action.payload) {
        state.isLoggedIn = true
      } else {
        state.isLoggedIn = false
      }
    },
    updateAuthLoading: (state, action) => {
      state.loading = action.payload
    },
    updateNewUser: (state, action) => {
      state.isNewUser = action.payload
    },
    updatePhotoURL: (state, action) => {
      state.user.photoURL = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        const data = action.payload
        state.user = data
        state.isLoggedIn = !!action.payload
        state.loading = false
      })
      .addCase(getUserData.rejected, (state) => {
        state.user = null
        state.isLoggedIn = false
        state.loading = false
      })
      .addCase(logoutAction.fulfilled, (state) => {
        state.user = null
        state.isLoggedIn = false
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = false
      })
  },
})

// Selectors
export const getAuthState = (state) => state.auth
export const useAuthSelector = () => useSelector(getAuthState)
// Reducers and actions
export const {
  updateRedirectUrl,
  updateUserData,
  updateAuthLoading,
  updatePhotoURL,
  updateNewUser,
} = authSlice.actions

export default authSlice.reducer
