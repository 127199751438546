import { Route, Routes, Navigate } from "react-router-dom"

import {
  AdminPage,
  ClientPage,
  DriverPage,
  NotFoundPage,
  AuthMicrosoftPage,
  SettingPage,
} from "./pages"

import { SavedLocationSettingView } from "./ui"
import Spinner from "./components/Spinner"

import { useAuthSelector } from "./redux/slices/authSlice"
import { allowedAuthorizationRoles } from "./utils/constants"

const routesList = [
  /** CLIENT ROUTES */
  {
    id: "client-home-page",
    path: "/",
    page: ClientPage,
    nestedRoutes: [
      {
        id: "client-saved-locations",
        path: "saved-locations",
        page: SavedLocationSettingView,
      },
    ],
  },
  /** DRIVER ROUTES */
  {
    id: "driver-home-page",
    path: "/",
    page: DriverPage,
    nestedRoutes: [
      {
        id: "driver-saved-locations",
        path: "saved-locations",
        page: SavedLocationSettingView,
      },
    ],
  },
  {
    id: "settings",
    path: "settings",
    page: SettingPage,
  },
  {
    id: "admin-home-page",
    path: "/",
    page: AdminPage,
  },
  {
    id: "session-page",
    path: "session",
    page: AuthMicrosoftPage,
  },
  {
    id: "public-redirect-page",
    path: "/",
    page: Navigate,
    props: {
      to: "/session",
    },
  },
  {
    id: "authenticated-redirect-page",
    path: "session",
    page: Navigate,
    props: {
      to: "/",
    },
  },
]

const clientRoutesId = [
  "client-home-page",
  "authenticated-redirect-page",
  "settings",
]
const driverRoutesId = [
  "driver-home-page",
  "authenticated-redirect-page",
  "settings",
]
const adminRoutesId = ["admin-home-page", "authenticated-redirect-page"]
const publicRoutesId = ["session-page", "public-redirect-page"]

const RoutesEnhancer = () => {
  const { loading, user } = useAuthSelector()
  const userRole = user?.role || "public"

  let derivedRoutes = []
  switch (userRole) {
    // FIRST ELEMENT OF ARRAY IS ADMIN, CLIENT, DRIVER
    case allowedAuthorizationRoles[0]:
      derivedRoutes = routesList.filter(({ id }) => adminRoutesId.includes(id))
      break

    case allowedAuthorizationRoles[1]:
      derivedRoutes = routesList.filter(({ id }) => clientRoutesId.includes(id))
      break
    case allowedAuthorizationRoles[2]:
      derivedRoutes = routesList.filter(({ id }) => driverRoutesId.includes(id))
      break
    case "public":
      derivedRoutes = routesList.filter(({ id }) => publicRoutesId.includes(id))
      break
    default:
      break
  }

  if (loading && userRole !== "public") {
    return <Spinner isVisible={true} />
  }
  return (
    <Routes>
      {derivedRoutes.map(
        ({ id, path, page: PageView, nestedRoutes, props }) => {
          return (
            <Route key={id} path={path} element={<PageView {...props} />}>
              {nestedRoutes &&
                nestedRoutes.map((nestObj) => {
                  const NestPage = nestObj.page
                  return (
                    <Route
                      key={nestObj.id}
                      path={nestObj.path}
                      element={<NestPage />}
                    />
                  )
                })}
            </Route>
          )
        }
      )}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default RoutesEnhancer
