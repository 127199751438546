import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { makeNoDaysArray } from "../../utils/dateUtils"
import { firestore, fbTimestamp } from "../../utils/firebase"
import i18n from "../../utils/i18n"

const initialState = {
  loading: false,

  selectedRide: false, // false or rideId
  selectedRideData: { formData: null, locationInfo: null },
}

/**
 * @typedef {Object} Location
 * @property {number} lat - Latitude of the location
 * @property {number} lng - Longitude of the location
 * @property {string} description - Description about the location.
 */

/**
 *
 * @param { rideId, driverInfo: {driverId, driverName} }
 * @returns
 */
export const updateRideAssigneeAction = createAsyncThunk(
  "rideSlicer/updateRideAssigneeAction",
  async (args, thunkApi) => {
    try {
      const authState = thunkApi.getState()?.auth
      if (!authState || !authState.user || authState?.user?.role === "client") {
        return thunkApi.rejectWithValue("invalid permissions")
      }
      // const { uid, role } = authState.user;
      console.log(`args`, args)
      if (!args || !args?.rideId) {
        return thunkApi.rejectWithValue(
          "Couldn't update assignee, provide valid params"
        )
      }

      let data = {
        driverInfo: !!args.driverInfo ? args.driverInfo : null,
        status: "pending",
        updatedAt: fbTimestamp.fromDate(new Date()),
      }

      // RIDE DOC REFERENCE
      const docRef = firestore.collection("rides").doc(args.rideId)
      // UPDATE ASSIGNEE
      await docRef.update(data)

      return "success"
    } catch (err) {
      console.log(`err`, err)
      const msg =
        err instanceof Error ? err.message : i18n.t("default.error.unknown")
      return thunkApi.rejectWithValue(msg)
    }
  }
)
/**
 *
 * @param { rideId, status }
 * @returns
 */
export const updateRideStatusAction = createAsyncThunk(
  "rideSlicer/updateRideStatusAction",
  async (args, thunkApi) => {
    try {
      const authState = thunkApi.getState()?.auth
      //removed || authState?.user?.role !== "admin" since client cant cancel...might need fixing later
      /*may need a check to determine if the user who created the ride is doing this operation
       in case of client*/
      if (!authState || !authState.user) {
        return thunkApi.rejectWithValue(i18n.t("default.error.permissions"))
      }
      // const { uid, role } = authState?.user

      if (!args || !args?.rideId || !args?.status) {
        return thunkApi.rejectWithValue(
          i18n.t("updateRide.error.statusParams")
        )
      }

      if (
        !["pending", "rejected", "approved", "cancelled"].includes(args?.status)
      ) {
        return thunkApi.rejectWithValue(
          i18n.t("updateRide.error.statusInvalid")
        )
      }

      let data = {
        status: args.status,
        updatedAt: fbTimestamp.fromDate(new Date()),
        ...(args?.status === "cancelled" && {
          cancelReason: args?.cancelReason || "",
        }),
      }

      // RIDE DOC REFERENCE
      const docRef = firestore.collection("rides").doc(args.rideId)
      // UPDATE ASSIGNEE
      await docRef.update(data)

      return "success"
    } catch (err) {
      console.log(`err`, err)
      const msg =
        err instanceof Error ? err.message : i18n.t("default.error.unknown")
      return thunkApi.rejectWithValue(msg)
    }
  }
)

/**
 * @typedef {Object} RideDetails
 * @property {string} title - title for the ride
 * @property {Location} from - from | departure, location
 * @property {Location} to - to | destination, location
 * @property {string} distance - {}
 */
/**
 *
 * @param { RideDetails } rideDetails
 * @returns
 */
export const createRideAction = createAsyncThunk(
  "rideSlicer/createRide",
  async (rides, thunkApi) => {
    const authState = thunkApi.getState()?.auth
    if (!authState || !authState.user) {
      return thunkApi.rejectWithValue(i18n.t("default.error.permissions"))
    }
    for (const rideDetails of rides) {

      try {
        const { uid, username, email } = authState?.user

        // LOCATION DOC REFERENCE
        const rideDocRef = firestore.collection(`rides`).doc()
        const rideId = rideDocRef.id

        const startDate = new Date(rideDetails?.locationInfo?.date)
        const endDate = new Date(rideDetails?.locationInfo?.date)
        endDate.setSeconds(
          endDate.getSeconds() + rideDetails?.duration?.value || 0
        )
        const driveDates = makeNoDaysArray(
          startDate,
          rideDetails?.duration?.value
        )
        // DATES
        const departureDate = fbTimestamp.fromDate(startDate)
        const arrivalDate = fbTimestamp.fromDate(endDate)

        const driverInfo = !!rideDetails.driverInfo ? rideDetails.driverInfo : null
        const eventTitle = !!rideDetails.eventTitle ? rideDetails.eventTitle : null
        const flightNumber = !!rideDetails.flightNumber ? rideDetails.flightNumber : null
        const obs = !!rideDetails.obs ? rideDetails.obs : null;
        const mainPerson = !!rideDetails.mainPerson ? rideDetails.mainPerson : null;
        const excludeWeekends = !!rideDetails.excludeWeekends ? rideDetails.excludeWeekends : null;
        const docData = {
          rideId,
          status: "pending",

          createdBy: { uid, username, email },

          createdAt: fbTimestamp.fromDate(new Date()),
          updatedAt: fbTimestamp.fromDate(new Date()),

          ...rideDetails,
          mainPerson,
          excludeWeekends,
          driverInfo,
          eventTitle,
          flightNumber,
          obs,
          locationInfo: {
            ...rideDetails.locationInfo,
            date: departureDate,
            dateMillis: departureDate.toMillis(),
            endDateMillis: arrivalDate.toMillis(),
          },
          driveDates,
        }
        // SAVE DOC IN FIRESTORE
        await rideDocRef.set(docData)

      } catch (err) {
        const msg =
          err instanceof Error ? err.message : i18n.t("default.error.unknown")
        return thunkApi.rejectWithValue(msg)
      }
    }
    return "success"
  }
)

export const updateRideAction = createAsyncThunk(
  "rideSlicer/updateRideAction",
  async (rideDetails, thunkApi) => {
    try {
      const authState = thunkApi.getState()?.auth
      if (!authState || !authState.user || !["admin", "driver", "client"].includes(authState?.user?.role)) {
        return thunkApi.rejectWithValue("Invalid permissions")
      }
      // const { uid, role } = authState?.user
      console.log(`rideDetails`, rideDetails)
      if (!rideDetails || !rideDetails.rideId) {
        return thunkApi.rejectWithValue(
          i18n.t("updateRide.error.paramsInvalid")
        )
      }

      // LOCATION DOC REFERENCE
      const rideDocRef = firestore.collection(`rides`).doc(rideDetails.rideId)

      const startDate = new Date(rideDetails?.locationInfo?.date)
      const endDate = new Date(rideDetails?.locationInfo?.date)
      endDate.setSeconds(
        endDate.getSeconds() + rideDetails?.duration?.value || 0
      )

      // DATES
      const departureDate = fbTimestamp.fromDate(startDate)
      const arrivalDate = fbTimestamp.fromDate(endDate)

      const docData = {
        ...rideDetails,
        locationInfo: {
          ...rideDetails.locationInfo,
          date: departureDate,
          dateMillis: departureDate.toMillis(),
          endDateMillis: arrivalDate.toMillis(),
        },
        updatedAt: fbTimestamp.fromDate(new Date()),
      }

      // SAVE DOC IN FIRESTORE
      await rideDocRef.update(docData)

      return "success"
    } catch (err) {
      const msg =
        err instanceof Error ? err.message : i18n.t("default.error.unknown")
      return thunkApi.rejectWithValue(msg)
    }
  }
)

export const rideSlice = createSlice({
  name: "rideSlicer",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setSelectedRideAction: (state, action) => {
      state.selectedRide = action.payload
    },
    setSelectedRideDataAction: (state, action) => {
      state.selectedRideData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRideAction.fulfilled, (state, action) => {
        console.log(`[createRideAction.fulfilled]`, action)
      })
      .addCase(createRideAction.rejected, (state, action) => {
        console.log(`[createRideAction.rejected]`, action)
      })
      .addCase(updateRideAction.fulfilled, (state, action) => {
        console.log(`[updateRideAction.fulfilled]`, action)
      })
      .addCase(updateRideAction.rejected, (state, action) => {
        console.log(`[updateRideAction.rejected]`, action)
      })
      .addCase(updateRideStatusAction.fulfilled, (state, action) => {
        console.log(`[updateRideStatusAction.fulfilled]`, action)
      })
      .addCase(updateRideStatusAction.rejected, (state, action) => {
        console.log(`[updateRideStatusAction.rejected]`, action)
      })
      .addCase(updateRideAssigneeAction.fulfilled, (state, action) => {
        console.log(`[updateRideAssigneeAction.fulfilled]`, action)
      })
      .addCase(updateRideAssigneeAction.rejected, (state, action) => {
        console.log(`[updateRideAssigneeAction.rejected]`, action)
      })
  },
})

// Reducers and actions
export const { setLoading, setSelectedRideAction, setSelectedRideDataAction } =
  rideSlice.actions

export const useRideSelector = () => useSelector((state) => state.rideSlicer)

export default rideSlice.reducer
