import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getUserData } from "../../redux/slices/authSlice"
import { auth, firestore } from "../../utils/firebase"
import { getUserGroups } from "../../utils/ad"
import { adminGroups, clientGroups, driverGroups } from "../../utils/constants"

const AuthWrapper = ({ children }) => {

  let sub = ()=>{}
  const dispatch = useDispatch()
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      try{
        if (user) {
          let userGroups = undefined
          const uid = user?.uid
          try{
            await auth.currentUser.getIdToken(true)
            const accessToken = localStorage.getItem("AZ_TOKEN")
            if(accessToken) {
              const AD = await getUserGroups(accessToken)
              if(!AD?.error){
                if(AD && AD.value && AD.value.length){
                    let role = "Unknown"
                    if(JSON.stringify(AD.value) !== JSON.stringify(userGroups)){
                      userGroups = AD.value.map(g => g?.id)
                      if (userGroups.some(group => adminGroups.includes(group))) {
                        role = "admin"
                      } else if (userGroups.some(group => clientGroups.includes(group))) {
                        role = "client"
                      } else if (userGroups.some(group => driverGroups.includes(group))) {
                        role = "driver"
                      }
                    }
                    await firestore.doc(`users/${uid}`).update({ groups: userGroups, role })
                    if(role === "Unknown") {
                      auth.signOut().catch(console.log)
                      window.location.replace("/")
                    }
                  }else {
                    await firestore.doc(`users/${user?.uid}`).update({ groups: [], role: "Unknown" })
                    auth.signOut().catch(console.log)
                    window.location.replace("/")
                  }
              }
            }
          }catch(err){
            console.log("Failed to get user groups", err)
          }
          dispatch(getUserData({uid, groups: userGroups}))
        }
      }catch(err){
        console.log("Authentication failed", err)
      }
    })
    return () => sub()
  }, [dispatch])
  return <>{children}</>
}

export default AuthWrapper
