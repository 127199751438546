import React, { useState } from "react"

import "./index.css"

import CreateRideModal from "../../modules/CreateRideModal"
import RideInfoModal from "../../modules/RideInfoModal"
import { notification } from "antd"
import { useDispatch } from "react-redux"
import { createRideAction } from "../../redux/slices/rideSlice"
import { setLoading } from "../../redux/slices/appSlice"
import i18n from "../../utils/i18n"

function NewRideFlow(props) {
  const { isShowing, onCancel, onFinish, savedLocation } = props
  const dispatch = useDispatch()
  const [isProcessStarted, setIsProcessStarted] = useState(false)
  const [isDrawerOneShowing, setIsDrawerOneShowing] = useState(false)
  const [isDrawerTwoShowing, setIsDrawerTwoShowing] = useState(false)
  const [valuesDrawerOne, setValuesDrawerOne] = useState(null)
  if (isShowing && !isProcessStarted) {
    setIsProcessStarted(true)
  }
  if (isProcessStarted && !isDrawerOneShowing && !isDrawerTwoShowing) {
    setIsDrawerOneShowing(true)
  }
  const OnFinishDrawerOne = (values) => {
    setValuesDrawerOne(values)
    setIsDrawerTwoShowing(true)
    setIsDrawerOneShowing(false)
  }

  const OnFinishDrawerTwo = (values, end) => {
    dispatch(setLoading(true))
    const rides = [values]
    const rideDay = values.locationInfo.date.clone()
    let nextDate = values.locationInfo.date.clone()

    const weekDay = () => values.excludeWeekends === true ? (nextDate.day() !== 6 && nextDate.day() !== 0) : true
    const beforeEnd = () => nextDate.isSameOrBefore(end, "day")

    const recurrence = (next) => {
      while (beforeEnd()) {
        nextDate = next()
        if (beforeEnd() && weekDay()) {
          rides.push({
            ...values,
            locationInfo: {
              ...values.locationInfo,
              date: nextDate
            }
          })
        }
      }
    }

    if (values.recurrence === "daily") {
      recurrence(() => nextDate.clone().add(1, 'days'))
    }
    else if (values.recurrence === "weekly") {
      recurrence(() => nextDate.clone().add(7, 'days'))
    }
    else if (values.recurrence === "monthly") {
      recurrence(() => {
        const next = nextDate.clone().add(1, 'month')
        if (rideDay.clone().date() === rideDay.clone().endOf("month").date()) {
          if (next.date() < next.clone().endOf("month").date()) {
            return next.clone().endOf("month")
          }
        }
        return next
      })
    }
    else if (values.recurrence === "monthly_weekday") {
      recurrence(() => {
        let next = nextDate.clone().add(1, 'month')
        next.startOf("month")
        while (next.weekday() !== rideDay.weekday()) {
          next = next.clone().add(1, 'days')
        }
        return next
      })
    }

    dispatch(createRideAction(rides))
      .unwrap()
      .then((res) => {
        notification.success({ message: i18n.t("createRide.message.created") })
        onFinish([])
      })
      .catch((err) => {
        notification.error({ message: err })
      })
      .finally(() => {
        OnCancel()
        dispatch(setLoading(false))
      })
  }

  const OnCancel = () => {
    onCancel()

    setIsProcessStarted(false)
    setIsDrawerOneShowing(false)
    setIsDrawerTwoShowing(false)
  }

  return (
    <>
      <CreateRideModal
        newRideModalIsShowing={isDrawerOneShowing}
        onCancel={OnCancel}
        onFinish={OnFinishDrawerOne}
        seedValue={savedLocation}
      />
      <RideInfoModal
        newRideModalIsShowing={isDrawerTwoShowing}
        onCancel={OnCancel}
        onFinish={OnFinishDrawerTwo}
        locationInfo={valuesDrawerOne}
        formPrepopulationData={{ locationInfo: valuesDrawerOne }}
        variant={"client"}
      />
    </>
  )
}

export default NewRideFlow
