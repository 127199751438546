import { combineReducers, configureStore } from "@reduxjs/toolkit"

import appReducer from "./slices/appSlice"
import rideReducer from "./slices/rideSlice"
import authReducer from "./slices/authSlice"

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["app", "rideSlicer", "auth"],
}
export const reducers = {
  app: appReducer,
  auth: authReducer,
  rideSlicer: rideReducer,
}
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(reducers)
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          // "auth/onUserSnapshot/fulfilled",
          "rideSlicer/setSelectedRideDataAction",
          "rideSlicer/setSelectedRideAction",
        ],
      },
    }),
})
export let persistor = persistStore(store)
// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch
