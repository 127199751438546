import React from "react"
import moment from "moment"
import { Layout, Typography, Empty } from "antd"
import EventCard from "../../components/EventCard"
import classes from "./index.module.css"

import { PropTypes } from "prop-types"
const { Content } = Layout

function renderHeadingDate(date) {
  return (
    <h2 style={{marginTop: "50px"}}>{moment(date).format("LL")}</h2>
  )
}

function EventFeed({ title, dataSource, onClick, category }) {
  let lastDate = new Date()
  let lastDateDifferent;
  return (
    <Content className={classes["contentWrap"]}>
      <Typography.Title level={4}>{title}</Typography.Title>
      {dataSource.map((item, i) => {
        const day = item?.locationInfo?.jsDate.getDate()
        const month = item?.locationInfo?.jsDate.getMonth()
        const year  = item?.locationInfo?.jsDate.getFullYear()
        const date = [day, month, year]
        if (date[0] !== lastDate.getDate() || date[1] !== lastDate.getMonth() || date[2] !== lastDate.getFullYear()) {
          lastDate = item.locationInfo.jsDate
          lastDateDifferent = true
        } else {
          lastDateDifferent = false
        }
        return (
          <>
            {lastDateDifferent && renderHeadingDate(lastDate)}
            <div className={classes["eventSmallListWrapper"]} key={i}>
              <EventCard
                startingDate={item?.locationInfo?.date}
                duration={item.duration?.text}
                driverId={item?.driverInfo?.driverId}
                asignee={item?.driverInfo?.driverName}
                // REPORTER IS THE PERSON WHO REQUESTED/CREATED THE RIDE
                reporter={item?.createdBy?.username}
                title={item.eventTitle}
                status={item.status}
                onClick={() => onClick({ ...item, category })}
                rideData={item}
              />
            </div>
          </>
        )
      })}
      {dataSource.length === 0 && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </Content>
  )
}

EventFeed.propTypes = {
  /**
   * Category is a unique identification for each feed type: maybe we can convert it to an enum later
   */
  category: PropTypes.string,
  /**
   * Title of the feed
   */
  title: PropTypes.string,
  /**
   * List of Rides
   */
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Id of the ride
       */
      key: PropTypes.string,
      /**
       * Id of the ride
       */
      rideId: PropTypes.string,
      /**
       * Status of the ride
       */
      status: PropTypes.string,
      /**
       * Creator of the ride
       */
      createdBy: PropTypes.shape({
        /**
         * Id of the user who created the ride
         */
        uid: PropTypes.string,
        /**
         * Name of the user who created the ride
         */
        username: PropTypes.string,
      }),
      /**
       * A person who is assigneed the ride
       */
      driverInfo: PropTypes.shape({
        /**
         * Id of the driver
         */
        driverId: PropTypes.string,
        /**
         * Name of the driver
         */
        driverName: PropTypes.string,
      }),
      /**
       * Date object representing creation date of ride
       */
      // createdAt: PropTypes.oneOfType([
      //   PropTypes.string,
      //   PropTypes.instanceOf(Date),
      // ]),
      createdAt: PropTypes.string,
      /**
       * Date object representing last time ride updated
       */
      updatedAt: PropTypes.string,

      /**
       * Title for the ride.
       */
      eventTitle: PropTypes.string,
      /**
       * Details for the ride parameters
       */
      locationInfo: PropTypes.shape({
        /**
         * Date object representing last time ride updated
         */
        date: PropTypes.string,
        dateMillis: PropTypes.number,
        /**
         * Pickup location of the ride.
         */
        fromAuto: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
          description: PropTypes.string,
        }),
        /**
         * Drop location of the ride.
         */
        toAuto: PropTypes.shape({
          lat: PropTypes.number,
          lng: PropTypes.number,
          description: PropTypes.string,
        }),
      }),
      /**
       * Distance b/w pickup-drop location of the ride.
       */
      distance: PropTypes.shape({
        /**
         * text representing the distance in Km.
         */
        text: PropTypes.string,
        /**
         * Value representing the distance in meter.
         */
        value: PropTypes.number,
      }),
      /**
       * Duration b/w pickup-drop location of the ride.
       */
      duration: PropTypes.shape({
        /**
         * text representing the duration in HMS.
         */
        text: PropTypes.string,
        /**
         * Value representing the duration in seconds.
         */
        value: PropTypes.number,
      }),
      /**
       * Name of the contact person.
       */
      mainPerson: PropTypes.string,
      /**
       * Phone number of the contact person.
       */
      mainPhone: PropTypes.string,
      /**
       * Number of people to transport.
       */
      numberPeople: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  /**
   * Fired on click and returns a function that has item+feed category details in first argument
   */
  onClick: PropTypes.func,
}
export default EventFeed
