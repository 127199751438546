import React, { useState, useEffect, useMemo } from "react"

import { Drawer, Form, Input, InputNumber, notification } from "antd"
// import classes from "./index.module.css"
import moment from "moment"
import isEqual from "lodash/isEqual"

import DrivingDistanceTime from "../../components/DrivingDistanceTime"
import RideInfoTitle from "./RideInfoTitle"
import InfoDecorator from "./InfoDecorator"
import RideInfoFooter from "./RideInfoFooter"
import { PropTypes } from "prop-types"

import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { getRouteDirectionLink } from "../../utils"

// REDUX ACTIONS
import { setLoading } from "../../redux/slices/appSlice"
import { updateRideAssigneeAction } from "../../redux/slices/rideSlice"
import { updateRideStatusAction } from "../../redux/slices/rideSlice"

function RideInfoModal({
  user,
  uid,
  newRideModalIsShowing,
  locationInfo,
  formPrepopulationData,
  variant,
  driversList = [],

  onCancel,
  onFinish,
}) {
  const { t } = useTranslation()
  let fromAutoDescription = locationInfo?.fromAuto.description
  let fromAutoLat = locationInfo?.fromAuto.lat
  let fromAutoLng = locationInfo?.fromAuto.lng
  let toAutoDescription = locationInfo?.toAuto.description
  let toAutoLat = locationInfo?.toAuto.lat
  let toAutoLng = locationInfo?.toAuto.lng
  let startDate = locationInfo?.jsDate ? locationInfo?.jsDate : moment(locationInfo?.date.toDate())
  let isReadOnly = ["client-restricted", "driver"].includes(variant)

  const [totalDistance, setTotalDistance] = useState(null)
  const [distanceValue, setDistanceValue] = useState(null)
  const [duration, setDuration] = useState(null)
  const [durationText, setDurationText] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isLocationAvailable, setIsLocationAvailable] = useState(true)
  const [isDataChanged, setIsDataChanged] = useState(false)
  const dispatch = useDispatch()

  const [areChangesMadeToTheMission, setAreChangesMadeToTheMission] =
    useState(false)

  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(formPrepopulationData)
    setIsDataChanged((prev) => !prev)
  }, [form, formPrepopulationData])
  useEffect(() => {
    if (!isLocationAvailable) {
      notification.error({
        message: t("createRide.error.wrongLocation"),
      })
    }
  }, [isLocationAvailable])
  const prepareToFinish = (values) => {
    onFinish({
      ...values,
      recurrence: locationInfo.recurrence,
      excludeWeekends: locationInfo.excludeWeekends,
      locationInfo: { fromAuto: values.locationInfo.fromAuto, toAuto: values.locationInfo.toAuto, date: values.locationInfo.date, dateMillis: values.locationInfo.dateMillis, endDateMillis: values.locationInfo.endDateMillis },
      distance: { text: totalDistance, value: distanceValue },
      duration: { text: durationText, value: duration },
    }, locationInfo.dateEnd)
    //TODO: Clear form after submission
    onCloseModal()
  }

  const onRideCancelled = (cancelReason = "") => {
    dispatch(setLoading(true))
    dispatch(
      updateRideStatusAction({
        rideId: formPrepopulationData?.rideId,
        status: "cancelled",
        cancelReason: cancelReason,
      })
    )
      .unwrap()
      .then(() => {
        notification.success({ message: t("createRide.cancelation.success") })
      })
      .catch((err) => {
        notification.error({ message: err })
      })
      .finally(() => {
        dispatch(setLoading(false))
        onCancel(true)
      })
  }

  const onRideApprove = () => {
    if (!formPrepopulationData.driverInfo) {
      notification.warning({
        message: t("createRide.error.assignDriver"),
      })
    } else {
      dispatch(setLoading(true))
      dispatch(updateRideStatusAction({ rideId: formPrepopulationData.rideId, status: "approved" }))
        .unwrap()
        .then(() => {
          notification.success({
            message: t("createRide.message.statusUpdated"),
          })
        })
        .catch((err) => {
          notification.error({ message: err })
          console.log(err)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }

  const assigneeHandler = (type, rideId) => {
    const driverInfo =
      type === "UN_ASSIGN"
        ? null
        : {
          driverId: user?.uid,
          driverName: user?.username,
        }
    dispatch(setLoading(true))
    dispatch(updateRideAssigneeAction({ rideId, driverInfo }))
      .unwrap()
      .then(() => {
        notification.success({ message: t("createRide.message.driverUpdated") })
      })
      .catch((err) => {
        console.log(`err`, err)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  function setETA(result) {
    if (result.rows[0].elements[0].status === "ZERO_RESULTS") {
      setIsLocationAvailable(false)

      onCancel(true)
      return
    }
    const duration = result.rows[0].elements[0].duration.value
    setDistanceValue(result.rows[0].elements[0].distance.value)
    setDurationText(result.rows[0].elements[0].duration.text)
    setTotalDistance(result.rows[0].elements[0].distance.text)
    setDuration(duration)
    setEndDate(moment(startDate).add(duration, "seconds"))
  }

  const onCloseModal = () => {
    // CLEAR FORM
    setAreChangesMadeToTheMission(false)
    form.setFieldsValue({})
    setTotalDistance(null)
    setDistanceValue(null)
    setDuration(null)
    setDurationText(null)
    setEndDate(null)

    // CALL THE CALLBACK ON CLOSE
    onCancel(false)
  }

  const routeLink = useMemo(() => {
    return getRouteDirectionLink(locationInfo?.fromAuto, locationInfo?.toAuto)
  }, [locationInfo])


  window.onpopstate = function (e) {
    onCloseModal()
    window.history.forward(1);
  }

  return (
    <Drawer
      title={
        <RideInfoTitle
          fromAutoDescription={fromAutoDescription}
          toAutoDescription={toAutoDescription}
          startDate={startDate}
          endDate={endDate}
          totalDistance={totalDistance}
          routeLink={routeLink}
        />
      }
      placement={"right"}
      closable={true}
      height={"100%"}
      onClose={onCloseModal}
      open={newRideModalIsShowing}
      key={"right"}
      contentWrapperStyle={{
        maxWidth: "600px",
        width: "100%",
        right: 0,
      }}
      footer={
        <RideInfoFooter
          driversList={driversList}
          areChangesMadeToTheMission={areChangesMadeToTheMission}
          driverInfo={formPrepopulationData?.driverInfo}
          isAlreadyCancelled={formPrepopulationData?.status === "cancelled"}
          //replace string later with userId
          isAlreadySelfAssigned={
            formPrepopulationData?.driverInfo?.driverId === user?.uid
          }
          onAssignSelf={(type) =>
            assigneeHandler(type, formPrepopulationData?.rideId)
          }
          onBookedOrSaved={form.submit}
          onCancelled={onRideCancelled}
          onApprove={onRideApprove}
          onDriverInfoChange={(info) => {
            //set field doesnt trigger change in antd form: manually interventing
            setAreChangesMadeToTheMission(true)
            form.setFields([{ name: "driverInfo", value: info }])
          }}
          variant={variant}
        />
      }
    >
      <Form
        form={form}
        name="control-hooks"
        onValuesChange={() => {
          setAreChangesMadeToTheMission(
            !isEqual(form.getFieldsValue(), formPrepopulationData)
          )
        }
        }
        onFinish={prepareToFinish}
        initialValues={{ numberPeople: 1, ...formPrepopulationData }}
      >
        <Form.Item
          name="driverInfo"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input type={"hidden"}></Input>
        </Form.Item>
        <Form.Item
          name="eventTitle"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            className="driverLogicInput"
            placeholder={t("modal.rideInfoModal.title.placeholder")}
            defaultValue=""
            style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
          />
        </Form.Item>
        <InfoDecorator
          duration={duration}
          endDate={endDate}
          fromAutoDescription={fromAutoDescription}
          startDate={startDate}
          totalDistance={totalDistance}
          toAutoDescription={toAutoDescription}
          isChecked={!!formPrepopulationData?.flightNumber}
          form={form}
          locationInfo={formPrepopulationData?.locationInfo}
          setAreChangesMadeToTheMission={setAreChangesMadeToTheMission}
        />

        <p>{t("modal.rideInfoModal.contactInformation")}</p>
        <Form.Item
          name="mainPerson"
          rules={[
            {
              required: true,
              message: t("modal.rideInfoModal.contactInformation.requiredName"),
            },
          ]}
        >
          <Input
            className="driverLogicInput"
            placeholder={t(
              "modal.rideInfoModal.contactInformation.placeholder1"
            )}
          />
        </Form.Item>
        <Form.Item
          name="mainPhone"
          rules={[
            {
              required: true,
              message: t("modal.rideInfoModal.contactInformation.requiredMainPhone"),
            },
          ]}
        >
          <Input
            className="driverLogicInput"
            placeholder={t(
              "modal.rideInfoModal.contactInformation.placeholder2"
            )}
          />
        </Form.Item>

        <p>{t("modal.rideInfoModal.noOfPeople")}</p>
        <Form.Item
          name="numberPeople"
          rules={[
            {
              required: false,
              type: "number",
              message: t("modal.rideInfoModal.contactInformation.requiredPeopleNumber"),
              min: 1,
              max: 10,
            },
          ]}
        >
          <InputNumber
            min={1}
            max={10}

            className="driverLogicInput"
          />
        </Form.Item>
        <p>{t("modal.rideInfoModal.obs")}</p>
        <Form.Item
          name="obs"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            className="driverLogicInput"
            defaultValue=""
            style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
          />
        </Form.Item>
        {/*   Cancel - Enter reason */}

        {(formPrepopulationData?.status === "cancelled") ?
          <><p> {t("modal.rideInfoModal.reasonCancel")}   </p><Form.Item
            name="cancelReason"
            rules={[
              {
                required: false,
                message: t("modal.rideInfoModal.reasonCancel")
              },
            ]}
          >
            <Input
              readOnly={isReadOnly}
              className="driverLogicInput"
              placeholder={t(
                "modal.rideInfoModal.reasonCancel"
              )} />
          </Form.Item></>
          : null}
      </Form>
      {newRideModalIsShowing && (
        <DrivingDistanceTime
          originLat={fromAutoLat}
          originLng={fromAutoLng}
          destinationLat={toAutoLat}
          destinationLng={toAutoLng}
          callback={(res) => setETA(res)}
          isDataChanged={isDataChanged}
        />
      )}
    </Drawer>
  )
}

RideInfoModal.propTypes = {
  /**
   * Current Authenticated user id
   */
  uid: PropTypes.string,
  /**
   * Different kind of modal have different functionality
   */
  variant: PropTypes.oneOf(["admin", "client", "driver", "client-restricted"]),
  /**
   * Check if this modal is showing
   */
  newRideModalIsShowing: PropTypes.bool,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  /**
   * Location info of the pickup location and destination
   */
  locationInfo: PropTypes.shape({
    fromAuto: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      description: PropTypes.string,
    }),
    toAuto: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      description: PropTypes.string,
    }),
    date: PropTypes.any,
  }),
  /**
   * In case the form needs to be prepopulated with data
   */
  formPrepopulationData: PropTypes.shape({
    driverInfo: PropTypes.shape({
      driverId: PropTypes.string,
      driverName: PropTypes.string,
    }),
    eventTitle: PropTypes.string,
    flightNumber: PropTypes.string,
    mainPerson: PropTypes.string,
    mainPhone: PropTypes.string,
    numberPeople: PropTypes.number,
    cancelReason: PropTypes.string,
  }),
}
export default RideInfoModal
