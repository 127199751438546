import React from "react"
import { Result, Button } from 'antd';
import { auth } from "../utils/firebase";

const NotFound = () => {

  function goBack(){
    auth.signOut().catch(console.log)
    window.location.replace("/")
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button onClick={goBack} type="primary">Go Back</Button>}
    />
  )
}

export default NotFound
