import React, { useEffect, useState } from "react"

import { Typography, Badge, Alert } from "antd"
import classes from "./index.module.css"
import { PropTypes } from "prop-types"
import { useTranslation } from "react-i18next"
import { getDriverPhoneNumber } from "../../utils/queries/client"
import { displayDate } from "../../utils/dateTime"
function EventCard({
  startingDate,
  asignee,
  reporter,
  driverId,
  duration,
  title,
  status,
  rideData,
  onClick,
}) {
  const { t } = useTranslation()
  // let { day, minutes, hour, month } = processDate(new Date(startingDate))
  // "eventCard.rideStatus.pending": "Pendente",
  // "eventCard.rideStatus.cancelled": "Cancelado",
  // "eventCard.rideStatus.rejected": "Rejeitado",
  // "eventCard.assignedTo": "Atribuído a",
  const [phoneNumber, setPhoneNumber] = useState(null)

  useEffect(() => {
    ;(async () => {
      try {
        const phone = await getDriverPhoneNumber(driverId)
        setPhoneNumber(phone)
      } catch (err) {
        console.log(err)
      }
    })()
  }, [driverId])

  const badgeMappings = {
    pending: ["processing", t("eventCard.rideStatus.pending")],
    rejected: ["error", t("eventCard.rideStatus.rejected")],
    approved: ["success", "Approved"],
    cancelled: ["error", t("eventCard.rideStatus.cancelled")],
  }
  let badgeText = badgeMappings[status]
  let whitespaceRemovedTitle = title?.trim()
  return (
    <div className={classes["eventSmallWrapper"]} onClick={onClick}>
      <div className={classes["eventSmallDateRow"]}>
        <Typography.Paragraph className={classes["eventSmallDateText"]}>
          {displayDate(startingDate, "dd/MM/yyyy [HH:mm]")} ({duration}){" "}
        </Typography.Paragraph>

        {status && badgeText && (
          <Badge status={badgeText[0]} text={badgeText[1]} />
        )}
      </div>
      <div className={classes.innerWrapper}>
        <div>
          <Typography.Title level={5}>
            {whitespaceRemovedTitle || ("["+t('calendar.events.notitle')+"]")}
          </Typography.Title>
          <Typography.Paragraph>
            <strong>{t("eventCard.requestedBy")}</strong>: {reporter || t('default.na')}
          </Typography.Paragraph>
        </div>
        <Typography.Paragraph>
          <p>
            <strong>{t("eventCard.assignedTo")}</strong>: {asignee || t('default.na')}
          </p>
          {phoneNumber && (
            <p>
              <strong>{t("eventCard.phoneNumber")}</strong>:{" "}
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </p>
          )}
        </Typography.Paragraph>
      </div>
      {status === "cancelled" && rideData?.cancelReason && (
        <Alert message={rideData?.cancelReason} type="info" showIcon />
      )}
    </div>
  )
}
EventCard.propTypes = {
  /**
   * Date object representing starting date of trip
   */
  startingDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  /**
   * Total Duration of the trip format 2h30(2 hours 30 minutes)
   */
  duration: PropTypes.string,
  /**
   * Title of the trip event
   */
  title: PropTypes.string,
  /**
   * Status of the trip
   */
  status: PropTypes.oneOf(["pending", "rejected", "approved", "cancelled"]),
  /**
   * Phone number of the driver
   */
  status: PropTypes.string,
  /**
   * Fired when clicked on card and delegated to parent
   */
  onClick: PropTypes.func,
  /**
   * Name of driver assigned to this mission
   */
  asignee: PropTypes.string,
  /**
   * Name of the person who created this mission
   */
  reporter: PropTypes.string,
  /**
   * Id of driver assigned to this mission
   */
  driverId: PropTypes.string,
}
export default EventCard
