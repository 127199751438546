import React, { useState } from "react"
import { Button, Modal, Form, Input } from "antd"
import { useTranslation } from "react-i18next"

const RideCancelConfirmation = ({ type = "primary", disabled, onConfirm }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [cancelReason, setCancelReason] = useState("")

  const showModal = () => {
    setOpen(true)
  }

  const handleOk = async () => {
    try {
      setLoading(true)
      await onConfirm(cancelReason)
      setOpen(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setCancelReason("")
  }

  return (
    <>
      <Button type={type} onClick={showModal} disabled={disabled}>
        {t("modal.rideInfoModal.footer.button.cancelRide")}
      </Button>
      <Modal
        open={open}
        title="Are you sure to cancel this Ride?"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Yes
          </Button>,
        ]}
      >
        <Form.Item label="Enter Reason">
          <Input
            placeholder="enter reason"
            value={cancelReason}
            onChange={({ target: { value } }) => setCancelReason(value)}
          />
        </Form.Item>
      </Modal>
    </>
  )
}

export default RideCancelConfirmation
