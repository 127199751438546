import React from "react"
import { Typography } from "antd"
import { PropTypes } from "prop-types"
const Logo = (props) => {
  return (
    <Typography.Title level={props.size} style={props.style}>
      DriverLogic
    </Typography.Title>
  )
}
Logo.propTypes = {
  /**
   * Size of the logo between 1-5
   */
  size: PropTypes.number,
  /**
   * Style object for additional styling
   */
  style: PropTypes.object,
}

export default Logo
