import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { firestore } from "../../utils/firebase"
import { getDrivers } from "../../utils/queries/client"

const initialState = {
  loading: false,
  savedLocations: {},
  drivers: [],
}

/**
 * @typedef {Object} Location
 * @property {number} lat - Latitude of the location
 * @property {number} lng - Longitude of the location
 * @property {string} description - Description about the location.
 */
/**
 *
 * @param { docId, title, Location } props
 * @returns
 * setSavedLocation()
 */
export const setSavedLocationAction = createAsyncThunk(
  "app/setSavedLocationsAction",
  async (args, thunkApi) => {
    try {
      const locationsObj = { ...args }
      const authState = thunkApi.getState()?.auth
      const uid = authState?.user?.uid

      console.log(`before-locationObj`, locationsObj)
      Object.keys(locationsObj).forEach(
        (key) => locationsObj[key] === undefined && delete locationsObj[key]
      )
      console.log(`after-locationObj`, locationsObj)

      // LOCATION DOC REFERENCE
      const docRef = firestore
        .collection("users")
        .doc(uid)
        .collection("settings")
        .doc("savedLocations")

      // UPDATE CURRENT USER SAVED LOCATION
      await docRef.set(locationsObj, { merge: true })

      return locationsObj
    } catch (err) {
      console.log(`err`, err)
      const msg =
        err instanceof Error ? err.message : "There is something error."
      return thunkApi.rejectWithValue(msg)
    }
  }
)
export const getSavedLocationAction = createAsyncThunk(
  "app/getSavedLocationAction",
  async (_, thunkApi) => {
    try {
      const authState = thunkApi.getState()?.auth
      const uid = authState?.user?.uid

      // LOCATION DOC REFERENCE
      const docRef = firestore
        .collection("users")
        .doc(uid)
        .collection("settings")
        .doc("savedLocations")

      // UPDATE CURRENT USER SAVED LOCATION
      const res = await docRef.get()

      return res.data()
    } catch (err) {
      console.log(`err`, err)
      const msg =
        err instanceof Error ? err.message : "There is something error."
      return thunkApi.rejectWithValue(msg)
    }
  }
)
export const getAllDrivers = createAsyncThunk(
  "app/getAllDrivers",
  async (_, thunkApi) => {
    try {
      // const authState = thunkApi.getState()?.auth
      // const uid = authState?.user?.uid

      // GET LIST OF DRIVERS
      const res = await getDrivers().get()
      const list = res.docs.map((doc) => doc.data())

      return list
    } catch (err) {
      console.log(`err`, err)
      const msg =
        err instanceof Error ? err.message : "There is something error."
      return thunkApi.rejectWithValue(msg)
    }
  }
)

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSavedLocationAction.fulfilled, (state, action) => {
        console.log(`[setSavedLocationAction.fulfilled]`, action)
        state.savedLocations = { ...state.savedLocations, ...action.payload }
      })
      .addCase(setSavedLocationAction.rejected, (state, action) => {
        console.log(`[setSavedLocationAction.rejected]`, action)
      })
      .addCase(getSavedLocationAction.fulfilled, (state, action) => {
        console.log(`[setSavedLocationAction.fulfilled]`, action)
        state.savedLocations = { ...state.savedLocations, ...action.payload }
      })
      .addCase(getSavedLocationAction.rejected, (state, action) => {
        console.log(`[setSavedLocationAction.rejected]`, action)
        state.savedLocations = {}
      })
      .addCase(getAllDrivers.fulfilled, (state, action) => {
        state.drivers = Array.isArray(action.payload) ? [...action.payload] : []
      })
      .addCase(getAllDrivers.rejected, (state) => {
        state.drivers = []
      })
  },
})

// Reducers and actions
export const { setLoading } = appSlice.actions

export const useAppSelector = () => useSelector((state) => state.app)

export default appSlice.reducer
