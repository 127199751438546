import React, { useEffect, useState } from "react"
import { DistanceMatrixService } from "@react-google-maps/api"

export default function DrivingDistanceTime(props) {
  const {
    originLat,
    originLng,
    destinationLat,
    destinationLng,
    callback,
    isDataChanged,
  } = props
  const [apiResponse, setApiResponse] = useState()

  useEffect(() => {
    // wil trigger new API response whenever props change
    setApiResponse(null)
  }, [originLat, originLng, destinationLat, destinationLng, isDataChanged])
  useEffect(() => {
    if (apiResponse != null) {
      callback(apiResponse)
      // setApiResponse(null)
    }
  }, [apiResponse])

  return (
    <>
      {!!!apiResponse && (
        <DistanceMatrixService
          options={{
            destinations: [{ lat: destinationLat, lng: destinationLng }],
            origins: [{ lng: originLng, lat: originLat }],
            travelMode: "DRIVING",
          }}
          callback={(res, status) => {
            setApiResponse(res)
          }}
        />
      )}
    </>
  )
}
