import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import AppEnhancer from "./AppEnhancer"
import "./utils/i18n"
import { Suspense } from "react"

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="...is loading">
      <AppEnhancer />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
)
