import React, { Fragment } from "react"
// import { capitalizeFirstLetter } from "../../utils/capitalizeString"
import { Typography, Drawer, Button, Form } from "antd"
import AddressInput from "../../components/AddressInput"
import { PropTypes } from "prop-types"
import { camelCase } from "lodash"
import { useTranslation } from "react-i18next"
import i18n from "../../utils/i18n"
function SaveLocationModal({
  isLoading,
  isShowing,
  savedLocations,
  onCancel,
  onFinish,
}) {
  let locations = ["home", "airport", "office", "train", "hotel"]
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const prepareToFinish = (values) => {
    onFinish(values)
    // form.resetFields()
  }

  let drawerFooter = (
    <div
      style={{
        textAlign: "right",
      }}
    >
      <Button
        onClick={form.submit}
        type="primary"
        disabled={isLoading}
        loading={isLoading}
      >
        {i18n.t("default.save")}
      </Button>
    </div>
  )
  let locationsInputs = locations.map((location, i) => {
    return (
      <Fragment key={i}>
        {" "}
        <Typography.Title level={5}>
          {t(`settings.${camelCase(location + " address")}`)}
        </Typography.Title>
        <Form.Item
          name={location}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <AddressInput
            placeholder={t("modal.createRideModal.placeholder.toWhere")}
            seedValue={savedLocations[location]?.description}
            selected={(value) => {
              ""
              form.setFieldsValue({ [location]: value })
            }}
          />
        </Form.Item>
      </Fragment>
    )
  })

  return (
    <Drawer
      title={t("sidebar.settings")}
      placement={"bottom"}
      closable={true}
      height={"75%"}
      onClose={() => onCancel(false)}
      open={isShowing}
      key={"bottom"}
      contentWrapperStyle={{
        height: "360px",
        maxWidth: "500px",
        right: 0,
      }}
      footer={drawerFooter}
    >
      <Form
        form={form}
        name="control-hooks"
        onFinish={prepareToFinish}
        initialValues={savedLocations}
      >
        {locationsInputs}
      </Form>
    </Drawer>
  )
}

SaveLocationModal.propTypes = {
  /**
   * loading when data is saving
   */
  isLoading: PropTypes.bool,
  /**
   * Control if this modal is visible
   */
  isShowing: PropTypes.bool,
  /**
   * Triggers when the modal is closed
   */
  onCancel: PropTypes.func,
  /**
   * Triggers when the save button is clicked
   */
  onFinish: PropTypes.func,
  /**
   * Object of saved locations to prepopulate form
   */
  savedLocations: PropTypes.objectOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      description: PropTypes.string,
    })
  ),
}

export default SaveLocationModal
