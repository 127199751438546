import React, { useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import SaveLocationModal from "../../components/SaveLocationModal"
import {
  setSavedLocationAction,
  getSavedLocationAction,
  useAppSelector,
} from "../../redux/slices/appSlice"

const SavedLocationSetting = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { savedLocations } = useAppSelector()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(getSavedLocationAction())
  }, [dispatch])
  const onSaveLocations = async (locations) => {
    try {
      setLoading(true)
      await dispatch(setSavedLocationAction(locations)).unwrap()
      setLoading(false)
    } catch (err) {
      console.log(`err`, err)
    }
  }
  return (
    <SaveLocationModal
      isLoading={isLoading}
      isShowing={true}
      savedLocations={savedLocations}
      onFinish={onSaveLocations}
      onCancel={() => navigate(-1)}
    />
  )
}

export default SavedLocationSetting
