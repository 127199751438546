import React, { useState, useEffect, useMemo } from "react"

import { useDispatch } from "react-redux"
import { Layout, Button, Space, notification } from "antd"
import { MenuUnfoldOutlined, CalendarOutlined } from "@ant-design/icons"

import CalendarView from "../../ui/RideViews/CalendarView"
import AdminLayout from "../../layouts/admin"
import RideInfoModal from "../../modules/RideInfoModal"
import { RidesTableView } from "../../ui/RideViews"
import { useTranslation } from "react-i18next"

//  REDUX ACTIONS/SELECTORS
import {
  getAllDrivers,
  setLoading,
  useAppSelector,
} from "../../redux/slices/appSlice"
import { useAuthSelector } from "../../redux/slices/authSlice"
import {
  setSelectedRideAction,
  useRideSelector,
  updateRideAssigneeAction,
  updateRideAction,
  setSelectedRideDataAction,
} from "../../redux/slices/rideSlice"
import isEqual from "lodash/isEqual"
import classes from "./index.module.css"
import RideFilters from "../../components/RideFilters"
import NewRideFlow from "../../components/NewRideFlow"
import i18n from "../../utils/i18n"
const { Content } = Layout

function AdminPage() {
  const dispatch = useDispatch()
  const { drivers } = useAppSelector()
  const { user } = useAuthSelector()
  const uid = user?.uid
  const username = user?.username
  const userRole = user?.role || "client-restricted"
  const { selectedRide, selectedRideData } = useRideSelector()
  const [showMissionList, setShowMissionList] = useState(true)
  const [newRideModalIsShowing, setNewRideModalIsShowing] = useState(false)
  console.log(userRole)
  // FILTERS FOR RIDES
  const [categoryFilter, setCategoryFilter] = useState("upcoming")
  const [statusFilter, setStatusFilter] = useState(["pending", "approved"])
  const [driverFilter, setDriverFilter] = useState("all")

  useEffect(() => {
    dispatch(getAllDrivers())
  }, [dispatch])

  const assigneeHandler = (type, rideId) => {
    const driverInfo =
      type === "UN_ASSIGN"
        ? null
        : {
          driverId: uid,
          driverName: username,
        }
    dispatch(setLoading(true))
    dispatch(updateRideAssigneeAction({ rideId, driverInfo }))
      .unwrap()
      .then(() => {
        notification.success({ message: "Assigned Driver updated." })
      })
      .catch((err) => {
        console.log(`err`, err)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const onFinishUpdate = (values = {}) => {
    const updatedValues = {
      eventTitle: values.eventTitle,
      driverInfo: values.driverInfo,
      flightNumber: values.flightNumber || null,
      mainPerson: values.mainPerson,
      mainPhone: values.mainPhone,
      numberPeople: values.numberPeople,
      obs: values.obs,
      locationInfo: values.locationInfo
    }
    if (
      !isEqual(updatedValues.driverInfo, selectedRideData?.formData?.driverInfo)
    ) {
      console.log(
        `[driver updated]`,
        updatedValues.driverInfo,
        selectedRideData?.driverInfo
      )
      updatedValues["status"] = "pending"
    }
    dispatch(setLoading(true))
    dispatch(updateRideAction({ rideId: selectedRide, ...updatedValues }))
      .unwrap()
      .then((res) => {
        notification.success({ message: "Ride Updated Successfully!" })
      })
      .catch((err) => {
        notification.error({ message: err })
        console.log(err)
      })
      .finally(() => {
        onCancel()
        dispatch(setLoading(false))
      })

    // console.log("FINAL VALUES", finalValues)
  }
  const onCancel = () => {
    dispatch(setSelectedRideAction(false))
    dispatch(setSelectedRideDataAction({ formData: null, locationInfo: null }))
  }
  const filterHandler = (type, value) => {
    switch (type) {
      case "category": return setCategoryFilter(value)
      case "status": return setStatusFilter(value)
      case "driver": return setDriverFilter(value)
    }
  }
  const variant = useMemo(
    () => (["client"].includes(userRole) ? "client-restricted" : userRole),
    [userRole]
  )

  return (
    <AdminLayout username={`${username}`[0].toUpperCase()}>
      <NewRideFlow
        isShowing={newRideModalIsShowing}
        onCancel={setNewRideModalIsShowing}
        onFinish={() => {
          // console.log(values)
          setNewRideModalIsShowing(false)
        }}
        variant={userRole}
      />
      <Content
        className={["buttonRowWrapper flex", classes["section"]].join(" ")}
        styles={{ width: "100%" }}
      >
        <Space style={{ flex: 1 }}>
          <Button onClick={() => setNewRideModalIsShowing(true)}>
            {i18n.t("default.newMission")}
          </Button>
          <Button
            ghost={false}
            onClick={() => setShowMissionList(!showMissionList)}
          >
            {showMissionList ? <CalendarOutlined /> : <MenuUnfoldOutlined />}
          </Button>
        </Space>
        <RideFilters
          showAll={showMissionList}
          style={{ marginLeft: "auto", width: "100%" }}
          categoryFilter={categoryFilter}
          statusFilter={statusFilter}
          driverFilter={driverFilter}
          changeHandler={filterHandler}
        />
      </Content>
      <Layout>
        <Content className={classes["section"]}>
          {!showMissionList && <CalendarView driverFilter={driverFilter} statusFilter={statusFilter} />}
          {showMissionList && (
            <RidesTableView
              uid={uid}
              userRole={userRole}
              categoryFilter={categoryFilter}
              statusFilter={statusFilter}
              driverFilter={driverFilter}
            />
          )}
        </Content>
      </Layout>
      <RideInfoModal
        user={user}
        uid={uid}
        newRideModalIsShowing={!!selectedRide}
        formPrepopulationData={selectedRideData.formData}
        locationInfo={selectedRideData.locationInfo}
        variant={variant}
        onFinish={onFinishUpdate}
        onCancel={onCancel}
        driversList={drivers}
      />
    </AdminLayout>
  )
}

export default AdminPage
