import React from "react"

import { Typography } from "antd"
import classes from "./index.module.css"
import { PropTypes } from "prop-types"
//tiles SVGs
import { ReactComponent as Citycar } from "../../images/tiles/citycar.svg"
import { useTranslation } from "react-i18next"
import _ from "lodash"
function Tile({ onClick, title, component = Citycar }) {
  const { t, i18n } = useTranslation()
  let Component = component
  return (
    <div className={classes["tileWrap"]} onClick={onClick}>
      <Component className={classes["svgTile"]} />
      <Typography.Title level={3}>
        {t(`tile.${_.camelCase(title)}`)}
      </Typography.Title>
    </div>
  )
}

Tile.propTypes = {
  /**
   * Delegates onClick to TitleList i.e parent
   */
  onClick: PropTypes.func,
  /**
   * Title of the tile
   */
  title: PropTypes.string,
  /**
   * SVG component that needs to be in tile
   */
  component: PropTypes.object,
}

export default Tile
