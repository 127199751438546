import { useState, useMemo } from "react"

import TableForRides from "../../components/TableForRides"
import RideItemAction from "../../components/RideItemActions"
import PaginationBar from "../../components/PaginationBar"

import { notification } from "antd"
import { usePagination } from "use-pagination-firestore"
import { getRides } from "../../utils/queries/client"
import { displayDate } from "../../utils/dateTime"

import {
  updateRideStatusAction,
  setSelectedRideAction,
  setSelectedRideDataAction,
} from "../../redux/slices/rideSlice"
import { useDispatch } from "react-redux"
import moment from "moment"
import i18n from "../../utils/i18n"

const TableView = ({
  uid,
  userRole,
  categoryFilter = "upcoming",
  statusFilter = "any",
  driverFilter = "all"
}) => {
  const dispatch = useDispatch()
  const [pageNo, setPageNo] = useState(1)

  const query = useMemo(
    () => getRides(uid, userRole, categoryFilter, statusFilter, driverFilter),
    [uid, userRole, categoryFilter, statusFilter, driverFilter]
  )
  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    query,
    { limit: 3 }
  )

  /** LIFE CYCLES AND HANDLERS */
  const pageHandler = (type) => {
    if (type === "previous") {
      getPrev()
      setPageNo((no) => no - 1)
    } else if (type === "next") {
      getNext()
      setPageNo((no) => no + 1)
    }
  }

  const ridesDataSource = useMemo(() => {
    return items.map((obj) => {
      return {
        ...obj,
        locationInfo: {
          ...obj.locationInfo,
          date: moment(obj?.locationInfo?.dateMillis),
          jsDate: obj?.locationInfo?.date?.toDate(),
        },
        createdAt: displayDate(obj?.createdAt, "dd/MM/yyyy [HH:mm]"),
        updatedAt: displayDate(obj?.updatedAt, "dd/MM/yyyy [HH:mm]"),
        key: obj.id,
      }
    })
  }, [items])

  const actionClickHandler = (record, status, reason) => {
    const rideId = record?.rideId
    if (!rideId) {
      return notification.error({
        message: "Ride Id not found",
      })
    }
    if (status === "view") {
      onFeedClicked(record)
    } else if (status === "approved") {
      if (!record.driverInfo) {
        notification.warning({
          message: i18n.t("createRide.error.assignDriver"),
        })
        onFeedClicked(record)
      } else {
        dispatch(updateRideStatusAction({ rideId, status: "approved" }))
          .unwrap()
          .then(() => {
            notification.success({
              message: i18n.t("createRide.message.statusUpdated"),
            })
          })
          .catch((err) => {
            notification.error({ message: err })
            console.log(err)
          })
      }
    } else if (status === "reject") {
      dispatch(updateRideStatusAction({ rideId, status: "rejected" }))
        .unwrap()
        .then(() => {
          notification.success({
            message: i18n.t("createRide.rejection.success"),
          })
        })
        .catch((err) => {
          notification.error({ message: err })
          console.log(err)
        })
    } else if (status === "cancel") {
      dispatch(
        updateRideStatusAction({
          rideId,
          status: "cancelled",
          cancelReason: reason,
        })
      )
        .unwrap()
        .then(() => {
          notification.success({
            message: i18n.t("createRide.cancelation.success"),
          })
        })
        .catch((err) => {
          notification.error({ message: err })
          console.log(err)
        })
    } else if (status === "pending") {
      dispatch(updateRideStatusAction({ rideId, status: "pending" }))
        .unwrap()
        .then(() => {
          notification.success({
            message: i18n.t("createRide.message.statusUpdated"),
          })
        })
        .catch((err) => {
          notification.error({ message: err })
          console.log(err)
        })
    }
  }
  const onFeedClicked = (args) => {
    dispatch(setSelectedRideAction(args.rideId))
    dispatch(
      setSelectedRideDataAction({
        formData: args,
        locationInfo: args.locationInfo,
      })
    )
  }

  return (
    <div>
      <TableForRides
        dataSource={ridesDataSource}
        isLoading={isLoading}
        ActionComponent={RideItemAction}
        onActionClick={actionClickHandler}
      />
      <br />
      <PaginationBar
        pageNo={pageNo}
        isStart={isStart}
        isEnd={isEnd}
        isLoading={isLoading}
        pageHandler={pageHandler}
      />
    </div>
  )
}

export default TableView
