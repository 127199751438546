import { Select, Space } from "antd"
import { useAppSelector } from "../../redux/slices/appSlice"
import classes from "./index.module.css"
import i18n from "../../utils/i18n"

const { Option } = Select

const RideFilters = ({ className, style, changeHandler, showAll, driverFilter }) => {
  const { drivers } = useAppSelector()

  return (
    <div className={classes["div"]}>
      <Space className={className} style={style}>
        {showAll && (
          <>
            <Select
              defaultValue="upcoming"
              style={{ width: 120 }}
              onChange={(value) => changeHandler("category", value)}
            >
              <Option value="past">{i18n.t("default.past")}</Option>
            </Select>

          </>
        )}
        <Select
          mode="multiple"
          defaultValue={["approved", "pending"]}
          className={classes["statusSelect"]}
          onChange={(value) => changeHandler("status", value)}
          allowClear
        >
          <Option value="pending">{i18n.t("default.pending")}</Option>
          <Option value="approved">{i18n.t("default.approved")}</Option>
          <Option value="rejected">{i18n.t("default.rejected")}</Option>
          <Option value="cancelled">{i18n.t("default.cancelled")}</Option>
        </Select>
        <Select
          defaultValue={driverFilter || "all"}
          style={{ width: 120 }}
          onChange={(value) => changeHandler("driver", value)}
        >
          <Option value="all">{i18n.t("default.all")}</Option>

          {drivers.map((driver) => {
            return <Option value={driver.username}>{driver.username}</Option>
          })}
        </Select>
      </Space>
    </div>
  )
}

export default RideFilters
