import { ReactComponent as Citycar } from "../../images/tiles/citycar.svg"
import { ReactComponent as Office } from "../../images/tiles/office.svg"
import { ReactComponent as Home } from "../../images/tiles/home.svg"
import { ReactComponent as Airport } from "../../images/tiles/aircraft.svg"

export const tileData = [
  {
    name: "ride",
    title: "Get a ride",
    component: Citycar,
  }
]
