export async function getUserGroups(accessToken) {
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`

    headers.append("Authorization", bearer)

    const options = {
        method: "GET",
        headers: headers
    }
    const res = await (await fetch("https://graph.microsoft.com/v1.0/me/memberOf", options)).json()
    console.log("AD_REQ:", res)
    return res
}