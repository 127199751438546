import React, { useState } from "react"
import classes from "./index.module.css"
import { Layout, Button } from "antd"
import SideBarMenu from "../../components/SideBarMenu"
import Logo from "../../components/Logo"
import { MenuOutlined } from "@ant-design/icons"
import LanguageSwitcher from "../../components/LanguageSwitcher"
const { Header, Content } = Layout

function MainLayoutHeader(props) {
  const [menuIsShowing, setMenuIsShowing] = useState(false)

  return (
    <>
      <SideBarMenu
        menuIsShowing={menuIsShowing}
        onClickOut={() => setMenuIsShowing(false)}
      />
      <Header className={classes["header"]}>
        <Button
          icon={<MenuOutlined />}
          type="text"
          onClick={() => setMenuIsShowing(true)}
        ></Button>
        <LanguageSwitcher></LanguageSwitcher>
      </Header>

      <Content className={classes["contentWrap"]}>
        <Logo size={1} />
      </Content>
    </>
  )
}

export default MainLayoutHeader
