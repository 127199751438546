import React, { useEffect, useState, useMemo } from "react"

import { Layout, Typography, Divider, notification } from "antd"
import { Outlet } from "react-router-dom"

// COMPONENTS
import NewRideFlow from "../../components/NewRideFlow"

// MODULES
import TileList from "../../modules/TileList"
import RideInfoModal from "../../modules/RideInfoModal"
import UpdatePhoneNumberModal from "../../modules/ActionModals/UpdatePhoneNumber"

// UI WIDGETS
import { RidesListView, RidesCalendarView } from "../../ui/RideViews"

import { MainLayout } from "../../layouts"

// REDUX
import { useDispatch } from "react-redux"
import {
  getAllDrivers,
  getSavedLocationAction,
  setLoading,
  useAppSelector,
} from "../../redux/slices/appSlice"
import { useAuthSelector } from "../../redux/slices/authSlice"
import {
  updateRideAction,
  useRideSelector,
  setSelectedRideAction,
  setSelectedRideDataAction,
} from "../../redux/slices/rideSlice"

import { useTranslation } from "react-i18next"
import classes from "./index.module.css"
import RideFiltersClient from "../../components/RidefiltersClient"
import isEqual from "lodash/isEqual"

const { Content } = Layout
function ClientPage() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user } = useAuthSelector()
  // const uid = user?.uid
  // const username = user?.username
  const userRole = user?.role || "client-restricted"
  const { savedLocations } = useAppSelector()
  const { selectedRide, selectedRideData } = useRideSelector()
  const [newRideModalIsShowing, setNewRideModalIsShowing] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(undefined)
  //Filters
  const [driverFilter, setDriverFilter] = useState("all")
  const [statusFilter, setStatusFilter] = useState(["pending", "approved"])

  useEffect(() => {
    dispatch(getSavedLocationAction())
    dispatch(getAllDrivers())
  }, [dispatch])
  const onFinish = (values) => {
    setNewRideModalIsShowing(false)
  }
  const [showMissionList, setShowMissionList] = useState(true)

  const variant = useMemo(
    () => (["client"].includes(userRole) ? "client-restricted" : userRole),
    [userRole]
  )
  const onFinishUpdate = (values = {}) => {
    const updatedValues = {
      eventTitle: values.eventTitle,
      driverInfo: values.driverInfo,
      flightNumber: values.flightNumber || null,
      mainPerson: values.mainPerson,
      mainPhone: values.mainPhone,
      numberPeople: values.numberPeople,
      locationInfo: values.locationInfo,
      obs: values.obs,
    }
    if (
      !isEqual(updatedValues.driverInfo, selectedRideData?.formData?.driverInfo)
    ) {
      console.log(
        `[driver updated]`,
        updatedValues.driverInfo,
        selectedRideData?.driverInfo
      )
      updatedValues["status"] = "pending"
    }
    dispatch(setLoading(true))
    const data = { rideId: selectedRide, ...updatedValues }
    dispatch(updateRideAction(data))
      .unwrap()
      .then((res) => {
        notification.success({ message: "Ride Updated Successfully!" })
      })
      .catch((err) => {
        notification.error("err", { message: err })
        console.log(err)
      })
      .finally(() => {
        onCancel()
        dispatch(setLoading(false))
      })

  }

  const onCancel = () => {
    dispatch(setSelectedRideAction(false))
    dispatch(setSelectedRideDataAction({ formData: null, locationInfo: null }))
  }

  const filterHandler = (type, value) => {
    if (type === "driver") {
      setDriverFilter(value)
    } else {
      setStatusFilter(value)
    }
  }
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
      window.history.go(1);
  };

  return (
    <MainLayout>
      <UpdatePhoneNumberModal defaultOpen={!user?.phoneNumber} user={user} />
      <NewRideFlow
        isShowing={newRideModalIsShowing}
        onCancel={setNewRideModalIsShowing}
        onFinish={onFinish}
        savedLocation={selectedLocation}
        variant={userRole}
      />

      <Content className={classes["contentWrap"]}>
        <TileList
          onClick={(key) => {
            setSelectedLocation(savedLocations[key])
            setNewRideModalIsShowing(true)
          }}
        />
      </Content>

      <Content
        className={classes["contentWrap"]}
        style={{ marginTop: "360px" }}
      >
        {userRole === "driver" && <RidesCalendarView driverFilter={driverFilter} statusFilter={statusFilter} />}
        <br />
        <Typography.Title level={4}>
          {t("feedTitle.nextTrips")}
        </Typography.Title>
        <div className={classes["eventSmallListWrapper"]}>
          {
            // NOTE: Add title for views to translations
          }
          <Content>
            {showMissionList && (
              <RideFiltersClient
                style={{ marginLeft: "auto", width: "100%" }}
                changeHandler={filterHandler}
              />
            )}
          </Content>
          <RidesListView
            title={t("feedTitle.upcomingRides")}
            category="upcoming"
            onClick={(...args) => ""}
            statusFilter={statusFilter}
            driverFilter={driverFilter}
          />
          <Divider />
          <RidesListView
            title={t("feedTitle.pastTrips")}
            category="past"
            onClick={(...args) => ""}
            statusFilter={statusFilter}
            driverFilter={driverFilter}
          />
        </div>
      </Content>
      <RideInfoModal
        user={user}
        uid={user?.uid}
        newRideModalIsShowing={!!selectedRide}
        formPrepopulationData={selectedRideData.formData}
        locationInfo={selectedRideData.locationInfo}
        variant={variant}
        onFinish={onFinishUpdate}
        onCancel={() => {
          dispatch(setSelectedRideAction(false))
          dispatch(
            setSelectedRideDataAction({ formData: null, locationInfo: null })
          )
        }}
      />
      <Outlet />
    </MainLayout>
  )
}

export default ClientPage
