import React, { useLayoutEffect } from "react"

import { Layout, Avatar, Button, Space, notification } from "antd"
import { useDispatch } from "react-redux"
import { logoutAction } from "../../redux/slices/authSlice"
import { setLoading } from "../../redux/slices/appSlice"
import { useTranslation } from "react-i18next"

import classes from "./adminlayout.module.css"
import Logo from "../../components/Logo"
import i18n from "../../utils/i18n"

const { Header } = Layout

function AdminLayout({ children, username }) {
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    document.body.style.backgroundColor = "rgb(240, 242, 245)"
  }, [])

  const handleLogout = () => {
    dispatch(setLoading(true))
    dispatch(logoutAction())
      .unwrap()
      .then(() => {
        notification.success({ message: "Logout successful" })
      })
      .catch((err) => {
        notification.error({ message: err?.message })
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  return (
    <Layout className={classes["admin-section"]}>
      <Header className={classes["mainHeader"]}>
        <Logo size={3} style={{ color: "white", marginTop: 10 }} />
        <Space>
          <Avatar>{username}</Avatar>
          <Button onClick={handleLogout}>{i18n.t("default.logout")}</Button>
        </Space>
      </Header>
      <Layout>{children}</Layout>
    </Layout>
  )
}

export default AdminLayout
