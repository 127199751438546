import { useTranslation } from "react-i18next"
import { Select } from "antd"
const { Option } = Select
const lngs = {
  en: { nativeName: "English" },
  pt: { nativeName: "Portuguese" },
}

function Index() {
  const { t, i18n } = useTranslation()
  const options = Object.keys(lngs).map((lng) => (
    <Option key={lng} value={lngs[lng]?.nativeName}>
      <p
        key={lng}
        style={{
          fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
        }}
        onClick={() => i18n.changeLanguage(lng)}
      >
        {lngs[lng]?.nativeName}
      </p>
    </Option>
  ))

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ marginRight: "10px" }}>{i18n.t("default.language")}:</span>
      {i18n?.language && (
        <Select
          value={lngs[i18n.language]?.nativeName}
          showArrow={false}
          notFoundContent={null}
          style={{ width: "150px" }}
          // className="driverLogicInput"
        >
          {options}
        </Select>
      )}
    </div>
  )
}

export default Index
