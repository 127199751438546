import React from "react"

import { Layout } from "antd"
import Header from "../../modules/Header"
import classes from "./index.module.css"

function MainLayout({ children }) {
  return (
    <Layout className={classes["layout"]}>
      <Header />
      {children}
    </Layout>
  )
}

export default MainLayout
