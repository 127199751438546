/**
 * @typedef {Object} ProcessedDate
 * @property {string} day - Day of the month
 * @property {string} month - Month name e.h Jan, Feb
 * @property {string} hour - Hour of time
 * @property {string} minutes - Minutes passed in hour
 */
/**
 *
 * @param {Date} date
 * @return {ProcessedDate} Date destructered into hour, minutes, day, and month
 */

import moment from "moment"

export const processDate = (date) => {
  const month = date.toLocaleString("default", { month: "short" })
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return {
    day: day,
    month: month,
    hour: hours,
    minutes,
  }
}

export const makeNoDaysArray = (departureDate, noOfSeconds) => {
  const noOfDays = Math.ceil(noOfSeconds / (3600 * 24))
  const arr = Array(noOfDays)
    .fill(0)
    .map((_, i) => {
      const str = moment(departureDate).add("days", i).format("YYYY-MM-DD")
      return str
    })
  return arr
}
