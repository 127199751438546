import format from "date-fns/format"
import { fbTimestamp } from "./firebase"

/**
 *
 * @param {any} date
 * @param {string} ff
 * @returns
 */
export const displayDate = (date, ff) => {
  if (!date) {
    return "Unavailable"
  }
  const dd = typeof date === "number" ? new Date(date) : date.toDate()
  const formatedDate = format(dd, `${ff}`)

  return formatedDate
}
/**
 *
 * @param {any} date
 * @returns
 */
export const getDate = (date) => {
  const dd = typeof date === "number" ? new Date(date) : date.toDate()

  return dd
}
export const getTodayDate = () => {
  const start = new Date()
  start.setHours(0, 0, 0, 0)
  return start
}

/**
 *
 * @param {string} str
 * @returns
 */
export const getMillisByStr = (str) =>
  fbTimestamp.fromDate(new Date(str)).toMillis()

/**
 *
 * @param {Date} date
 * @returns
 */

export const getMillisByDate = (date) => fbTimestamp.fromDate(date).toMillis()

export const getTodayDateMillis = () => {
  const start = new Date()
  start.setHours(0, 0, 0, 0)
  return getMillisByDate(start)
}
