import React, { memo } from "react"
import { Space, Button, Popconfirm } from "antd"
import RideCancelConfirmation from "./CancelConfirmation"
import i18n from "../../utils/i18n"

const RideItemAction = memo(({ status, record, onActionClick = () => {} }) => {
  return (
    <Space size="middle" wrap={true}>
      {true && (
        <Button onClick={() => onActionClick(record, "view")}>{i18n.t("default.view")}</Button>
      )}
      {["pending", "reject"].includes(status) && (
        <Popconfirm
          title="Are you sure Approve the Ride?"
          onConfirm={() => onActionClick(record, "approved")}
        >
          <Button>{i18n.t("default.approve")}</Button>
        </Popconfirm>
      )}
      {["pending", "approved"].includes(status) && (
        <Popconfirm
          title="Are you sure Reject the Ride?"
          onConfirm={() => onActionClick(record, "reject")}
        >
          <Button>{i18n.t("default.reject")}</Button>
        </Popconfirm>
      )}
      {["reject", "approved"].includes(status) && (
        <Popconfirm
          title="Are you sure to move ride into Pending"
          onConfirm={() => onActionClick(record, "pending")}
        >
          <Button>{i18n.t("default.pending")}</Button>
        </Popconfirm>
      )}
      {!["cancelled"].includes(status) && (
        <RideCancelConfirmation
          onConfirm={(reason) => onActionClick(record, "cancel", reason)}
          type=""
        />
      )}
    </Space>
  )
})

export default RideItemAction
