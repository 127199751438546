import React, { useEffect, useState } from "react"

import { Form, Input, Row, Checkbox, DatePicker } from "antd"
import { CaretDownOutlined, SendOutlined } from "@ant-design/icons"
// import classes from "./index.module.css"
import moment from "moment"
import { PropTypes } from "prop-types"
import { processDate } from "../../../utils/dateUtils"
import { formatDuration, intervalToDuration } from "date-fns"
import { useTranslation } from "react-i18next"
import AddressInput from "../../../components/AddressInput"

const RideDecorator = ({
  fromAutoDescription,
  toAutoDescription,
  totalDistance,
  duration,
  endDate,
  startDate,
  isChecked = false,
  isReadOnly,
  form,
  locationInfo,
  setAreChangesMadeToTheMission
}) => {
  const { t } = useTranslation()
  // "modal.rideInfoModal.flightNumber": "Flight number?",
  // "modal.rideInfoModal.flightNumber.placeholder": "Flight number or other transportation information?",
  const [hasFlightNumber, setHasFlightNumber] = useState(isChecked)
  useEffect(() => {
    setHasFlightNumber(isChecked)
  }, [isChecked])
  const existFormatDuration = typeof duration === "number" || !!endDate
  return (
    <div style={{ position: "relative" }}>
      <Row style={{ marginBottom: 15 }}>
        <span style={{marginTop: "10px"}} >{moment(startDate).format("HH[:]mm")}</span>
        <ArrowSymbol />
        <div style={{ marginLeft: "50px", flex: 1 }}>

          <Form.Item
            name={["locationInfo", "fromAuto"]}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <AddressInput
              placeholder={t("modal.createRideModal.placeholder.fromWhere")}
              selected={(value) => {
                form.setFieldValue(["locationInfo", "fromAuto"], value)
              }}
              onChange={() => setAreChangesMadeToTheMission(true)}
              seedValue={locationInfo?.fromAuto.description}
              style={{maxWidth: "447px"}}
            />
          </Form.Item>
        </div>
      </Row>
      <Row>
        <p style={{ paddingLeft: 88, color: "#6b62ff" }}>
          {totalDistance}
          {existFormatDuration &&
            ` (${formatDuration(
              intervalToDuration({ start: 0, end: duration * 1000 })
            )})`}
        </p>
      </Row>
      <Row style={{ marginBottom: 15 }}>
        <span style={{marginTop: "10px"}}>
          {moment(endDate || startDate || new Date()).format("HH[:]mm")}
        </span>
        <div style={{ marginLeft: "50px", flex: 1 }}>
          <Form.Item
            name={["locationInfo", "toAuto"]}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <AddressInput
              placeholder={t("modal.createRideModal.placeholder.toWhere")}
              selected={(value) => { form.setFieldValue(["locationInfo", "toAuto"], value) }}
              seedValue={locationInfo?.toAuto.description}
              onChange={() => setAreChangesMadeToTheMission(true)}
              style={{maxWidth: "447px"}}
            />
          </Form.Item>

          <Form.Item
            name={["locationInfo", "date"]}
            rules={[
              {
                required: true,
                message: t("modal.createRideModal.error.whatDayAndTime"),
              },
            ]}
          >
            <DatePicker
              placeholder={t("modal.createRideModal.placeholder.whatDayAndTime")}
              selected={(value) => { form.setFieldValue(["locationInfo", "date"], value) }}
              seedValue={locationInfo?.date}
              showTime={{ format: "HH [h] mm" }}
              showSecond={false}
              format={(value) => { 
               return value.format("DD MMM, YYYY - HH [h] mm")
              }}
              className="driverLogicInput"
              popupStyle={{}}
              disabledDate={(currentDate) => {
                return moment().add(-1, "days") >= currentDate
              }}
              onChange={() => setAreChangesMadeToTheMission(true)}
            />
          </Form.Item>
          
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              marginTop: 15,
            }}
          >
            <SendOutlined
              rotate={-45}
              style={{ marginRight: 8, color: "#6b62ff" }}
            />
            <Checkbox
              onChange={(e) => setHasFlightNumber(e.target.checked)}
              checked={hasFlightNumber}
              style={{ color: "#6b62ff" }}
              disabled={isReadOnly}
            >
              {t("modal.rideInfoModal.flightNumber")}
            </Checkbox>
          </div>
          {hasFlightNumber && (
            <Form.Item
              name="flightNumber"
              style={{ marginTop: 25 }}
              rules={[
                {
                  required: false,
                  message: "No flight number has been set",
                },
              ]}
            >
              <Input
                readOnly={isReadOnly}
                className="driverLogicInput"
                placeholder={t("modal.rideInfoModal.flightNumber.placeholder")}
              />
            </Form.Item>
          )}
        </div>
      </Row>
    </div>
  )
}
RideDecorator.propTypes = {
  /**
   * if prepopulated data exists give it true otherwise false
   */
  isChecked: PropTypes.bool,
  /**
   * If readonly disable inputs
   */
  isReadOnly: PropTypes.bool,
  fromAutoDescription: PropTypes.string,
  toAutoDescription: PropTypes.string,
  totalDistance: PropTypes.string,
  duration: PropTypes.any,
  endDate: PropTypes.any,
  startDate: PropTypes.any,
}
export default RideDecorator

const ArrowSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "absolute",
        left: 52,
        marginTop: 20,
      }}
    >
      <div
        style={{
          width: 8,
          height: 8,
          backgroundColor: "gray",
          borderRadius: 4,
        }}
      />
      <div style={{ width: 2, height: 120, backgroundColor: "gray" }} />
      <CaretDownOutlined style={{ color: "gray", marginTop: "-4px" }} />
    </div>
  )
}
