import React from "react"

import { Typography, Row, Avatar } from "antd"
import classes from "./index.module.css"
import { PropTypes } from "prop-types"
function SideBarMenu({ fullName }) {
  const parseFullNameToInitials = (name) => {
    let nameInitials = ""
    name.split(" ").forEach((val) => {
      nameInitials += val.charAt(0).toUpperCase()
    })
    return nameInitials
  }
  return (
    <>
      <Row className={classes["sideMenuAvatarRow"]}>
        <Avatar size="large">{parseFullNameToInitials(fullName)}</Avatar>
        <Typography.Title level={5} className={classes["sideMenuNameText"]}>
          {fullName}
        </Typography.Title>
      </Row>
    </>
  )
}
SideBarMenu.propTypes = {
  /**
   * Full name of the person who is logged in
   */
  fullName: PropTypes.string,
}

export default SideBarMenu
