import React, { useEffect, useState, useMemo } from "react"
import CalendarForRides from "../../components/CalendarForRides"

import { useAppSelector } from "../../redux/slices/appSlice"
import { getCalendarRides } from "../../utils/queries/client"
import { useDispatch } from "react-redux"
import { displayDate } from "../../utils/dateTime"
import {
  useRideSelector,
  setSelectedRideAction,
  setSelectedRideDataAction,
} from "../../redux/slices/rideSlice"
import moment from "moment"
import i18n from "../../utils/i18n"

const CalendarView = ({driverFilter = "all", statusFilter=["pending", "approved"]}) => {
  const dispatch = useDispatch()
  const { drivers } = useAppSelector()
  const { selectedRide } = useRideSelector()
  const [selectedDate, setSelectedDate] = useState(() =>
    new Date().toISOString()
  )
  const [rides, setrides] = useState([])
  
  useEffect(() => {
  if(selectedRide === false){
    fetchRides()
  }}, [selectedRide, driverFilter, statusFilter])
  const dateChangeHandler = async (date) => {
    try {
      setSelectedDate(date)
      fetchRides(date)
    } catch (err) {
      console.log(`err`, err)
    }
  }
  const fetchRides = async (date) => {
    try {
      const res = await getCalendarRides(driverFilter, statusFilter).get()
      setrides(res.docs.map((doc) => doc.data()))
    } catch (err) {
      console.log(`err`, err)
    }
  }

  const clickMission = (args) => {
    dispatch(setSelectedRideAction(args.rideId))
    dispatch(
      setSelectedRideDataAction({
        formData: args,
        locationInfo: args.locationInfo,
      })
    )
  }
  let driversList = useMemo(() => {
    return [
      ...drivers.map((driversArray) => {
        return {
          id: driversArray.uid,
          title: driversArray.username,
        }
      }),
      { id: 0, title: "Unassigned" },
    ]
  }, [drivers])

  const ridesList = useMemo(() => {
    return rides.map((obj) => {
      const duration = obj.duration.value
      const statusMsg = obj.status
      let itemtxt = "black"
      let itemColor = "white"
      if (statusMsg === "pending") {
        itemColor = "orange"
        itemtxt = "white"
      } else if (statusMsg === "approved") {
        itemColor = "rgb(77 168 69)"
        itemtxt = "white"
      } else if (["rejected", "cancelled"].includes(statusMsg)) {
        itemColor = "#fe4a44"
        itemtxt = "white"
      }

      const serializeObj = {
        ...obj,
        locationInfo: {
          ...obj.locationInfo,
          date: moment(obj?.locationInfo?.dateMillis),
          jsDate: obj?.locationInfo?.date?.toDate(),
        },
        createdAt: displayDate(obj?.createdAt, "dd/MM/yyyy [HH:mm]"),
        updatedAt: displayDate(obj?.updatedAt, "dd/MM/yyyy [HH:mm]"),
        key: obj.id,
      }
      if (obj.driverInfo != null) {
        return {
          id: obj?.rideId, // rideId
          title: obj.eventTitle || i18n.t('calendar.event.notitle'), // eventTitle 
          group: obj.driverInfo.driverId, // driverId
          driver: obj.driverInfo,
          start_time: obj?.locationInfo?.dateMillis, //date
          end_time: obj?.locationInfo?.dateMillis + duration * 1000,
          itemProps: {
            style: {
              color: itemtxt,
              background: itemColor,
            },
            onDoubleClick: () => {
              clickMission(serializeObj)
            },
          },
        }
      } else
        return {
          id: obj?.rideId, // rideId
          title: obj.eventTitle || i18n.t('calendar.event.notitle'), //title eventTitle
          group: 0, // driverId
          start_time: obj?.locationInfo?.dateMillis, //date
          end_time: obj?.locationInfo?.dateMillis + duration * 1000,
          itemProps: {
            style: {
              color: itemtxt,
              background: itemColor,
            },
            onDoubleClick: () => {
              clickMission(serializeObj)
            },
          },
        }
    })
  }, [rides])

  return (
    <div>
      <CalendarForRides
        driverList={driversList}
        rideList={ridesList}
        selectedDate={selectedDate}
        dateChangeHandler={dateChangeHandler}
      />
    </div>
  )
}

export default CalendarView
