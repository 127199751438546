import React from "react"

import { Button, Drawer, notification } from "antd"
import classes from "./index.module.css"
import { PropTypes } from "prop-types"
import Avatar from "../Avatar"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { logoutAction } from "../../redux/slices/authSlice"
import { setLoading } from "../../redux/slices/appSlice"
import { useTranslation } from "react-i18next"
import i18n from "../../utils/i18n"
function SideBarMenu(props) {
  const { t } = useTranslation()
  const { menuIsShowing, onClickOut } = props
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const handleLogout = () => {
    dispatch(setLoading(true))
    dispatch(logoutAction())
      .unwrap()
      .then(() => {
        notification.success({ message: i18n.t("default.logout.success") })
      })
      .catch((err) => {
        notification.error({ message: err?.message })
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
  return (
    <Drawer
      placement={"left"}
      closable={true}
      width={"300px"}
      onClose={onClickOut}
      open={menuIsShowing}
      key={"left"}
      headerStyle={{ display: "none" }}
      drawerStyle={{
        backgroundColor: "#000",
        paddingTop: "env(safe-area-inset-top)",
      }}
    >
      <Avatar fullName={user?.username || "None"} />
      <ul className={classes["sideMenuOptionsList"]}>
        <li>
          <Link to="#past" onClick={onClickOut}>
            {t("sidebar.pastTrips")}
          </Link>
        </li>
        <li>
          <Link to="/saved-locations" onClick={onClickOut}>
            {t("sidebar.savedlocations")}
          </Link>
        </li>
        <li>
          <Link to="/settings" onClick={onClickOut}>
            {t("sidebar.settings")}
          </Link>
        </li>
        <li>
          <Link to="#contacts" onClick={onClickOut}>
            {t("sidebar.contacts")}
          </Link>
        </li>
      </ul>
      <Button onClick={handleLogout}>{t("sidebar.logout")}</Button>
    </Drawer>
  )
}
SideBarMenu.propTypes = {
  /**
   * Checks if menu is visible
   */
  menuIsShowing: PropTypes.bool,
  /**
   * Fired when the drawer is closed
   */
  onClickOut: PropTypes.func,
}

export default SideBarMenu
