import React from "react"
import moment from "moment"

import { PropTypes } from "prop-types"

import "react-calendar-timeline/lib/Timeline.css"
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Tooltip } from 'antd';
import i18n from "../../utils/i18n"

const localizer = momentLocalizer(moment)
function CalendarForRides({
  rideList,
}) {

  // normal events with duration inferior to 24h
  const events = rideList.map(ride => ({
    ...ride,
    start: new Date(ride.start_time),
    end: new Date(ride.end_time),
    id: ride.id,
    title: (
      <Tooltip title={ride.title}>
        <div style={{ display: 'flex', flexDirection: 'row', fontSize: '11.2px', }} >
          {!ride.driver && (<div style={{ color: 'black', marginRight: '7px', fontWeight: 'bold' }}>{i18n.t('calendar.event.nodriver')}</div>)}
          <div>{ride.title}</div>
          {ride.driver && (<div style={{ color: 'black', marginLeft: '7px', fontWeight: 'bold' }}>{ride.driver.driverName}</div>)}
        </div>
      </Tooltip>
    ),
  }))

  // events with duration superior to 24h
  const backgroundEvents = [...events].filter(event => 
    event.start.getDate() !== event.end.getDate()
  )

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        backgroundEvents={backgroundEvents}
        onSelectEvent={e => e.itemProps?.onDoubleClick?.(e)}
        defaultView="month"
        drilldownView="day"
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        eventPropGetter={(e) => ({ style: e.itemProps.style })}
        tooltipAccessor="false"
      />
    </div>
  )
}
CalendarForRides.propTypes = {
  /**
   * Driver's Data
   */
  driverList: PropTypes.array,
  /**
   * Ride's Data
   */
  rideList: PropTypes.array,
  /**
   * trigger when date changes
   */
  dateChangeHandler: PropTypes.func,
}

export default CalendarForRides
