import React, { useState } from "react"
import { DatePicker, Drawer, Button, Form, Row, Checkbox } from "antd"
import moment from "moment"

import AddressInput from "../../components/AddressInput"
import { PropTypes } from "prop-types"
// import classes from "./index.module.css"
import { useTranslation } from "react-i18next"
import { Select } from "antd"
import { notification } from "antd"
import i18n from "../../utils/i18n"

const { Option } = Select

function CreateRideModal({
  newRideModalIsShowing,
  onCancel,
  onFinish,
  seedValue,
}) {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isOnce, setIsOnce] = useState(true)

  // "modal.createRideModal.button.continue": "Continue",

  const prepareToFinish = (values) => {

    if (values.date && values.dateEnd) {
      const start = values.date.clone()
      const end = values.dateEnd.clone()

      if (values.excludeWeekends && values.recurrence !== "once" && ((start.day() === 6 || start.day() === 0) || (end.day() === 6 || end.day() === 0))) {
        notification.error({ message: i18n.t("createRide.message.weekendExclusion") })
        return
      }

    }

    onFinish(values)
    form.resetFields()
    setIsOnce(true)
  }

  let drawerFooter = (
    <div
      style={{
        textAlign: "right",
      }}
    >
      <Button onClick={form.submit} type="primary">
        {t("modal.createRideModal.button.continue")}
      </Button>
    </div>
  )

  return (
    <Drawer
      title={t("modal.createRideModal.title")}
      placement={"bottom"}
      closable={true}
      height={"fit-content"}
      onClose={() => {
        form.resetFields()
        setIsOnce(true)
        onCancel(false)
      }}
      open={newRideModalIsShowing}
      key={"bottom"}
      contentWrapperStyle={{
        maxWidth: "500px",
        right: 0,
      }}
      footer={drawerFooter}
    >
      <Form form={form} name="control-hooks" onFinish={prepareToFinish}>
        <Form.Item
          name="fromAuto"
          rules={[
            {
              required: true,
              message: t("modal.createRideModal.error.fromWhere"),
            },
          ]}
        >
          <AddressInput
            placeholder={t("modal.createRideModal.placeholder.fromWhere")}
            selected={(value) => form.setFieldsValue({ fromAuto: value })}
          />
        </Form.Item>
        <Form.Item
          name="toAuto"
          rules={[
            {
              required: true,
              message: t("modal.createRideModal.error.toWhere"),
            },
          ]}
        >
          <AddressInput
            placeholder={t("modal.createRideModal.placeholder.toWhere")}
            selected={(value) => form.setFieldsValue({ toAuto: value })}
            seedValue={seedValue?.description}
          />
        </Form.Item>
        <Form.Item
          name="recurrence"
          rules={[
            {
              required: true,
              message: t("modal.createRideModal.error.recurrence"),
            },
          ]}
          style={{ width: "100%", flex: 1 }}
        >
          <Select
            onChange={(val) => {
              setIsOnce(val === "once")
              if (val === "once") form.setFieldsValue({ excludeWeekends: false })
            }}
            placeholder={t('modal.createRideModal.placeholder.recurrence')}
            className="driverLogicInput"
          >
            <Option value="once">{t('modal.createRideModal.recurrence.once')}</Option>
            <Option value="daily">{t('modal.createRideModal.recurrence.daily')}</Option>
            <Option value="weekly">{t('modal.createRideModal.recurrence.weekly')}</Option>
            <Option value="monthly">{t('modal.createRideModal.recurrence.monthly')}</Option>
            {/* <Option value="monthly_weekday">{t('modal.createRideModal.recurrence.monthly_weekday')}</Option> */}
          </Select>
        </Form.Item>
        <Row style={{ gap: "1rem" }}>
          <Form.Item
            style={{ display: "flex", flex: 1 }}
            name="date"
            rules={[
              {
                required: true,
                message: t("modal.createRideModal.error.whatDayAndTime"),
              },
            ]}
          >
            <DatePicker
              placeholder={t("modal.createRideModal.placeholder.whatDayAndTime")}
              showTime={{ format: "HH [h] mm" }}
              showSecond={false}
              format={(value) => value.format("DD MMM, YYYY - HH [h] mm")}
              className="driverLogicInput"
              popupStyle={{}}
              disabledDate={(currentDate) => {
                return moment().add(-1, "days") >= currentDate
              }}
            />
          </Form.Item>
          {isOnce ? null : (
            <Form.Item
              style={{ display: "flex", flex: 1 }}
              name="dateEnd"
              rules={[
                {
                  required: true,
                  message: t("modal.createRideModal.error.whatDayAndTime"),
                },
              ]}
            >
              <DatePicker
                placeholder={t("modal.createRideModal.placeholder.whatDayAndTimeEnd")}
                showTime={{ format: "HH [h] mm" }}
                showSecond={false}
                format={(value) => value.format("DD MMM, YYYY - HH [h] mm")}
                className="driverLogicInput"
                popupStyle={{}}
                disabledDate={(currentDate) => {
                  return moment().add(-1, "days") >= currentDate
                }}
              />
            </Form.Item>
          )}
        </Row>
        {isOnce ? null : (
          <Form.Item
            style={{ display: "flex", flex: 1 }}
            name="excludeWeekends"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              style={{ color: "#6b62ff" }}
            >
              {t("modal.createRideModal.excludeWeekends")}
            </Checkbox>
          </Form.Item>
        )}

      </Form>
    </Drawer>
  )
}

CreateRideModal.propTypes = {
  /**
   * Control if this modal is visible
   */
  newRideModalIsShowing: PropTypes.bool,
  /**
   * Triggers when the modal is closed
   */
  onCancel: PropTypes.func,
  /**
   * Triggers when the information is submitted
   */
  onFinish: PropTypes.func,

  /**
   * Seed value will be present in case the the user selects ride from saved locations
   */
  seedValue: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    description: PropTypes.string,
  }),
}

export default CreateRideModal
