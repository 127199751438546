import React, { useState, useEffect, useRef } from "react"
import { Modal, Form, Input, Button, notification } from "antd"
import classes from "./index.module.css"
import { useDispatch } from "react-redux"
import { login, loginWithMicrosoft } from "../../redux/slices/authSlice"
import { setLoading } from "../../redux/slices/appSlice"
import { useNavigate } from "react-router-dom"
function Index() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [variant, setVariant] = useState("Login")
  const ref = useRef()
  const handleAuthentication = (values) => {
    console.log(values)
    if (variant === "Login") {
      dispatch(setLoading(true))
      dispatch(login(values))
        .unwrap()
        .then((val) => {
          navigate("/")
        })
        .catch((err) => {
          console.log(err?.message, "Ylolo")
          notification.error({ message: err?.message })
          form.resetFields()
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
      //Login Thunk
    } else if (variant === "Signup") {
      // Signup Thunk
      // dispatch(setLoading(true))
      // dispatch(signup(values))
      //   .unwrap()
      //   .then(() => {
      //     navigate("/")
      //   })
      //   .catch((err) => {
      //     console.log(err?.message, "Ylolo")
      //     form.resetFields()
      //     notification.error({ message: "Can't signup at the moment!" })
      //   })
      //   .finally(() => {
      //     dispatch(setLoading(false))
      //   })
      notification.error({ message: "Can't signup at the moment!" })
    }
  }
  const animateUnderLine = (variant) => {
    const element = ref?.current
    let distance = variant === "Login" ? 0 : 100
    if (element) {
      element.animate([{ transform: `translateX(${distance}%)` }], {
        duration: 200,
        fill: "forwards",
      })
    }
  }
  useEffect(() => {
    animateUnderLine(variant)
  }, [variant])

  const handleMicrosoftLogin = () => {
    dispatch(setLoading(true))
    dispatch(loginWithMicrosoft())
      .unwrap()
      .then((val) => {
        console.log("val", val)
      })
      .catch((err) => {
        console.log(err?.message, "Ylolo")
        notification.error({ message: err?.message })
        form.resetFields()
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  return (
    <div>
      <Modal
        open={true}
        closable={false}
        title={
          <div className={classes.buttonWrapper}>
            <span ref={ref} className={classes.after}></span>
            <div
              className={classes.slidingButton}
              onClick={() => setVariant("Login")}
            >
              Login
            </div>
            <div
              className={classes.slidingButton}
              onClick={() => setVariant("Signup")}
            >
              Signup
            </div>
          </div>
        }
        footer={
          <div>
            <Button type="primary" onClick={handleMicrosoftLogin}>
              Login with Microsoft
            </Button>
            <Button type="primary" onClick={form.submit}>
              {variant}
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          name="auth"
          autoComplete="off"
          onFinish={handleAuthentication}
        >
          <Form.Item
            name={"email"}
            rules={[
              {
                required: true,
                message: "Valid email is required",
                type: "email",
              },
            ]}
          >
            <Input className="driverLogicInput" placeholder="Email"></Input>
          </Form.Item>
          <Form.Item
            name={"password"}
            rules={[
              {
                required: true,
                message: "Password is required",
              },
              { min: 6, message: "Password must be minimum 5 characters." },
            ]}
          >
            <Input
              className="driverLogicInput"
              placeholder="Password"
              type={"password"}
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default Index
