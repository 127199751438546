import React, { useState, useMemo } from "react"

import { Layout } from "antd"
import { PropTypes } from "prop-types"

import PaginationBar from "../../components/PaginationBar"
import EventFeed from "../../modules/EventFeed"

import { usePagination } from "use-pagination-firestore"
import { getRides } from "../../utils/queries/client"
import { displayDate } from "../../utils/dateTime"

// REDUX ACTIONS
import { useDispatch } from "react-redux"
import { useAuthSelector } from "../../redux/slices/authSlice"
import {
  useRideSelector,
  setSelectedRideAction,
  setSelectedRideDataAction,
} from "../../redux/slices/rideSlice"
import moment from "moment"

const { Content } = Layout

function ListView({ title, category, statusFilter, driverFilter }) {
  const dispatch = useDispatch()
  const { user } = useAuthSelector()
  const uid = user?.uid
  const username = user?.username
  const userRole = user?.role || "client-restricted"
 
  const [pageNo, setPageNo] = useState(1)
  // const [selectedRide, setSelectedRide] = useState(false)
  const { selectedRide, selectedRideData } = useRideSelector()
  const query = useMemo(
    () => getRides(uid, userRole, category, statusFilter, driverFilter),
    [uid, category, userRole, statusFilter.length, driverFilter]
  )
  const { items, isLoading, isStart, isEnd, getPrev, getNext } = usePagination(
    query,
    { limit: 10 }
  )

  const pageHandler = (type) => {
    if (type === "previous") {
      getPrev()
      setPageNo((no) => no - 1)
    } else if (type === "next") {
      getNext()
      setPageNo((no) => no + 1)
    }
  }

  const ridesDataSource = useMemo(() => {
    return items.map((obj) => {
      return {
        ...obj,
        locationInfo: {
          ...obj.locationInfo,
          date: moment(obj?.locationInfo?.dateMillis),
          jsDate: obj?.locationInfo?.date?.toDate(),
        },
        createdAt: displayDate(obj?.createdAt, "dd/MM/yyyy [HH:mm]"),
        updatedAt: displayDate(obj?.updatedAt, "dd/MM/yyyy [HH:mm]"),
        key: obj.id,
      }
    })
  }, [items])

  const variant = useMemo(
    () => (["client"].includes(userRole) ? "client-restricted" : userRole),
    [userRole]
  )

  const onFeedClicked = (args) => {
    // setSelectedRide(args.rideId)
    dispatch(setSelectedRideAction(args.rideId))
    dispatch(
      setSelectedRideDataAction({
        formData: args,
        locationInfo: args.locationInfo,
      })
    )
  }

  // const selectedRideData = useMemo(() => {
  //   if (!!selectedRide) {
  //     const ride = ridesDataSource.find((obj) => obj.rideId === selectedRide)
  //     return { formData: ride, locationInfo: ride.locationInfo }
  //   } else {
  //     return { formData: null, locationInfo: null }
  //   }
  // }, [selectedRide, ridesDataSource])
  return (
    <Content id={category}>
      <EventFeed
        dataSource={ridesDataSource}
        {...{ title, category }}
        onClick={onFeedClicked}
      />
      {/* <RideInfoModal
        user={user}
        uid={uid}
        newRideModalIsShowing={!!selectedRide}
        formPrepopulationData={selectedRideData.formData}
        locationInfo={selectedRideData.locationInfo}
        variant={variant}
        onCancel={() => {
          dispatch(setSelectedRideAction(false))
          dispatch(
            setSelectedRideDataAction({ formData: null, locationInfo: null })
          )
        }}
      /> */}
      <br />
      <PaginationBar
        pageNo={pageNo}
        isStart={isStart}
        isEnd={isEnd}
        isLoading={isLoading}
        pageHandler={pageHandler}
      />
    </Content>
  )
}

ListView.propTypes = {
  /**
   * Category is a unique identification for each feed type: maybe we can convert it to an enum later
   */
  category: PropTypes.string,
  /**
   * Title of the feed
   */
  title: PropTypes.string,
  /**
   * Fired on click and returns a function that has item+feed category details in first argument
   */
  onClick: PropTypes.func,
}
export default ListView
