import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import App from "./App"

// LIB IMPORTS
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

// LOCAL IMPORTS
import { persistor, store } from "./redux/store"
import AuthWrapper from "./ui/AuthWrapper"

const AppEnhancer = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <AuthWrapper>
            <App />
          </AuthWrapper>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default AppEnhancer
